import { ReactNode, useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthActionType, AuthContext } from '../../store/auth'
import { ModalView } from '../ModalView'
import { IconType, renderIcon } from './Icon'

interface ItemProps {
    children?: ReactNode
    text: string
    icon: IconType
    mobile: boolean
    to: string
}
export const NavItem: FunctionComponent<ItemProps> = ({
    children,
    text,
    icon,
    mobile,
    to,
}) => {
    return (
        <NavLink
            to={to}
            className={
                'navlink ' +
                (mobile
                    ? 'py-3 my-3 flex items-center mobile'
                    : 'm-2 xl:m-4 flex items-center justify')
            }
            data-testid={(mobile ? 'mobile-' : '') + to + '-navbutton'}
        >
            {renderIcon(icon, mobile ? 'inline w-7 ml-6' : 'm-2 stroke')}
            {mobile ? (
                <div className="ml-5 mr-auto">{text}</div>
            ) : (
                <span title={text}>{text}</span>
            )}
        </NavLink>
    )
}
interface LogoutProps {
    children?: ReactNode
    text: string
    icon: IconType
    mobile: boolean
}
export const LogOutItem: FunctionComponent<LogoutProps> = ({
    text,
    icon,
    mobile,
}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [, dispatch] = useContext(AuthContext)
    return (
        <>
            <button
                onClick={() => {
                    setModalOpen(true)
                }}
                className={
                    ' ' +
                    (mobile
                        ? 'navlink py-3 my-3 flex items-center mobile w-full'
                        : 'border-cyan border rounded-sm py-1 px-6 text-cyan m-3 xl:m-6 hover:bg-white/25 transition-colors')
                }
            >
                {mobile ? renderIcon(icon, 'inline w-7 ml-6') : ''}
                {mobile ? <div className="ml-5 mr-auto">{text}</div> : text}
            </button>
            {modalOpen ? (
                <ModalView
                    title="Sign Out"
                    isModalShowing={modalOpen}
                    setIsModalShowing={setModalOpen}
                >
                    <>
                        <div className="w-full flex justify-center">
                            <h4 className="text-center my-6 w-44">
                                Are you sure you want to sign out?
                            </h4>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={() => {
                                    setModalOpen(false)
                                }}
                                className=" font-bold w-32 h-10 m-2 border-cyan border text-cyan rounded-sm hover:bg-white/25 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                className="font-bold w-32 h-10 m-2 bg-[#9B0000] hover:bg-[#9C3D3D] rounded-sm transition-colors"
                                onClick={() => {
                                    dispatch({ type: AuthActionType.Logout })
                                }}
                            >
                                Sign Out
                            </button>
                        </div>
                    </>
                </ModalView>
            ) : (
                <></>
            )}
        </>
    )
}
