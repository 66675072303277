import { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Loading from './pages/Loading'
import './App.css'
import { Nav } from './components/Nav/Nav'
import Dashboard from './pages/Dashboard/Dashboard'
import Admin from './pages/Admin/Admin'
import LockEventManagement from './pages/LockEventManagement'
import { AuthStatus, setupAuth, AuthContext } from './store/auth'

function App() {
    const [authState, authDispatch] = useContext(AuthContext)
    const [locationShowing, setLocationShowing] = useState(true)

    useEffect(() => {
        setupAuth(authDispatch).then()
    }, [authDispatch])

    switch (authState.status) {
        case AuthStatus.Pending:
        case AuthStatus.NoUser:
            return <Loading></Loading>
        case AuthStatus.Authenticated:
            return (
                <Nav locationShowing={locationShowing}>
                    <Routes>
                        <Route path="/dashboard/" element={<Dashboard />} />
                        <Route path="/admin/" element={<Admin setLocationShowing={setLocationShowing} />} />
                        <Route
                            path="/lock-event-management/"
                            element={<LockEventManagement />}
                        />
                        <Route
                            path="/"
                            element={<Navigate replace to="/dashboard/" />}
                        />
                    </Routes>
                </Nav>
            )
        case AuthStatus.Failed:
            return (
                <div className="bg-[#070717] w-screen h-screen flex justify-center items-center">
                    <div className="text-white text-3xl">{authState.error}</div>
                </div>
            )
        default:
            return (
                <div className="bg-[#070717] w-screen h-screen flex justify-center items-center">
                    <div className="text-white text-3xl">
                        Failed Authentication
                    </div>
                </div>
            )
    }
}

export default App
