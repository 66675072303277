import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { enableMapSet } from 'immer'
import { Contexts } from './store/root'

enableMapSet()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Contexts>
                <App />
            </Contexts>
        </BrowserRouter>
    </React.StrictMode>
)
