import { FunctionComponent } from 'react'
import { Spinner } from './Spinner'
import PlusIcon from '../assets/svg/PlusIcon.svg'
export enum ButtonType {
    Primary,
    Secondary,
    Warning,
}

export type ButtonProps = {
    text: string
    type?: ButtonType
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    submitting?: boolean
    disabled?: boolean
    testid?: string
    plus_icon?: Boolean
}

export const Button: FunctionComponent<ButtonProps> = ({
    text,
    testid,
    onClick,
    type = ButtonType.Primary,
    submitting = false,
    disabled = false,
    plus_icon,
}) => {
    let classes = 'bg-cyan text-black hover:bg-[#33B7C4] disabled:bg-[#93B1B4]'
    switch (type) {
        case ButtonType.Secondary:
            classes = 'border-cyan border text-cyan hover:bg-white/25'
            break

        case ButtonType.Warning:
            classes =
                'bg-[#9B0000] hover:bg-[#9C3D3D] text-white disabled:text-white/70'
            break
    }
    return (
        <button
            className={
                classes + (plus_icon
                    ? ' pr-8 pl-4 '
                    : ' px-8 ') +
                    ' flex items-center h-12 rounded-sm shrink-0 transition-colors box-border'
            }
            onClick={onClick}
            disabled={submitting || disabled}
            data-testid={testid}
        >
            {plus_icon ? <img src={PlusIcon} alt="Plus Icon" /> : <></>}
            {submitting && (
                <Spinner
                    width="1.5rem"
                    height="1.5rem"
                    classes="stroke-white/70 mr-2"
                />
            )}
            <div className="text-[14px] h-min font-bold">{text}</div>
        </button>
    )
}
