import { FunctionComponent } from 'react'
import { ILock, LockDisplayState } from '../../store/locks'
import { DashboardIcon } from './LockIcon'

export type LockStatusIconProps = {
    lock: ILock
}

export const LockStatusIcon: FunctionComponent<LockStatusIconProps> = ({
    lock,
}) => {
    let lock_color = 'white'
    let circle_color = 'white'
    let circle_fill = 'transparent'
    let text = 'UNLOCKED'
    switch (lock.displayState) {
        case LockDisplayState.Offline:
            circle_color = 'transparent'
            text = 'OFFLINE'
            break
        case LockDisplayState.Unlocked:
            lock_color = '#2184CC'
            circle_color = 'url(#circle_enabled_gradient)'
            text = 'UNLOCKED'
            break
        case LockDisplayState.Locked:
            text = 'LOCKED'
            break
        case LockDisplayState.Locking:
            circle_fill = '#FFFFFF33'
            circle_color = 'transparent'
            lock_color = '#FFFFFF80'
            text = 'LOCKING...'
            break
        case LockDisplayState.Unlocking:
            circle_fill = 'url(#circle_unlocking_gradient)'
            circle_color = 'transparent'
            lock_color = '#275373'
            text = 'UNLOCKING...'
            break
    }
    return (
        <svg viewBox="0 0 300 300" className="m-4 mb-8">
            <defs>
                <linearGradient
                    id="circle_enabled_gradient"
                    x1="26.9999"
                    y1="0"
                    x2="26.9999"
                    y2="300"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.265625" stopColor="#2FD89B" />
                    <stop offset="300" stopColor="#2492E1" />
                </linearGradient>
                <linearGradient
                    id="circle_unlocking_gradient"
                    x1="150"
                    y1="0"
                    x2="150"
                    y2="300"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#2285CC" stopOpacity="0.28" />
                    <stop offset="300" stopColor="#2FD89C" />
                </linearGradient>
            </defs>
            <circle
                stroke={circle_color}
                strokeWidth={8}
                fill={circle_fill}
                r="144"
                cx="150"
                cy="150"
            />
            <DashboardIcon state={lock.displayState} height={125} y={40} />
            <text
                textAnchor="middle"
                fontSize={30}
                x={150}
                y={220}
                fill={lock_color}
                fontWeight={700}
                letterSpacing={3.5}
            >
                {text}
            </text>
        </svg>
    )
}
