import { Amplify, API, Auth, Hub } from 'aws-amplify'
import { createContext, Dispatch } from 'react'
import PubSub, { AWSIoTProvider } from '@aws-amplify/pubsub'

import { IoTClient, AttachPolicyCommand } from '@aws-sdk/client-iot'
import { ILock, lockAction, lockActionType, LockState } from '../store/locks'
export const APIName = 'APIGateway'

declare global {
    interface Window {
        BCAAConfig: {
            region: string
            userPoolId: string
            userPoolWebClientId: string
            identityPoolId: string
            domain: string
            endpoint: string
            aws_pubsub_endpoint: string
        }
    }
}
const config = window.BCAAConfig

export enum AuthStatus {
    Pending,
    Failed,
    Authenticated,
    NoUser,
}

export const AuthContext = createContext<
    [AuthState, React.Dispatch<AuthAction>]
>([{ status: AuthStatus.Pending }, () => {}])

export type AuthState =
    | { status: AuthStatus.Pending }
    | { status: AuthStatus.NoUser }
    | { status: AuthStatus.Failed; error: string }
    | { status: AuthStatus.Authenticated }

export enum AuthActionType {
    Logout,
    NoUserFound,
    Authenticated,
    Failed,
    Pending,
}

export type AuthAction =
    | { type: AuthActionType.Logout }
    | { type: AuthActionType.NoUserFound }
    | { type: AuthActionType.Pending }
    | { type: AuthActionType.Authenticated }
    | { type: AuthActionType.Failed; error: string }

export function authReducer(state: AuthState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionType.Logout:
            if (state.status === AuthStatus.Authenticated) {
                Auth.signOut()
            }
            return { status: AuthStatus.NoUser }
        case AuthActionType.Failed:
            return { status: AuthStatus.Failed, error: action.error }
        case AuthActionType.NoUserFound:
            return { status: AuthStatus.NoUser }
        case AuthActionType.Authenticated:
            return {
                status: AuthStatus.Authenticated,
            }
        case AuthActionType.Pending:
            return { status: AuthStatus.Pending }
    }
}
export async function setupAuth(dispatch: Dispatch<AuthAction>) {
    console.log('Starting auth setup')
    dispatch({ type: AuthActionType.Pending })
    const awsauth = {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
        identityPoolId: config.identityPoolId,
        mandatorySignIn: true,
        oauth: {
            domain: config.domain,
            scope: ['aws.cognito.signin.user.admin', 'openid'],
            redirectSignIn: `${window.location.origin}/dashboard/`,
            redirectSignOut: `${window.location.origin}/`,
            responseType: 'code',
        },
    }
    Auth.configure(awsauth)
    API.configure({
        endpoints: [
            {
                name: APIName,
                endpoint: config.endpoint,
                custom_header: async () => {
                    return {
                        Authorization: await getAuthToken(),
                        'Correlation-Object': '{"correlationId":"adf"}',
                    }
                },
            },
        ],
    })

    Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
                return getSession(dispatch)
            case 'signOut':
                dispatch({ type: AuthActionType.Logout })
                break
        }
    })
    return await getSession(dispatch)
}

export async function getUsernameAndEmail() {
    let credentails = { name: '', email: '' }

    Auth.currentUserPoolUser().then((data) => {
        credentails.name = data.signInUserSession.idToken.payload.name
        credentails.email = data.signInUserSession.idToken.payload.email
    })
    return credentails
}

export async function SetupIot() {
    const client = new IoTClient({
        region: config.region,
        credentials: Auth.currentCredentials,
    })
    const command = new AttachPolicyCommand({
        policyName: 'SudoPolicy',
        target: (await Auth.currentUserCredentials()).identityId,
    })
    const data = await client.send(command)
    console.log(data)
    Amplify.addPluggable(
        new AWSIoTProvider({
            aws_pubsub_region: config.region,
            aws_pubsub_endpoint: config.aws_pubsub_endpoint,
        })
    )
}

export async function IoTSubSetup(
    locks: Map<string, ILock>,
    lockDispatch: Dispatch<lockAction>,
    forceUpdate: () => void,
    setUpdatedLockID: (lockID: string) => void
) {
    locks.forEach((lock: ILock, key: string) => {
        const topic = `${lock.getTopic()}/status`
        lock.sub = PubSub.subscribe(topic).subscribe({
            next: (data: any) => {
                if (data !== undefined) {
                    lockDispatch({
                        type: lockActionType.LockUpdate,
                        lockId: lock.deviceId,
                        newState:
                            data.value.isLocked === true
                                ? LockState.Locked
                                : LockState.Unlocked,
                    })
                    setUpdatedLockID(lock.deviceId)
                    forceUpdate()
                }
            },
            error: (error: any) => console.error(error),
            complete: () => console.log('Done'),
        })
    })
}
async function getSession(dispatch: Dispatch<AuthAction>) {
    try {
        await Auth.currentSession()
        dispatch({
            type: AuthActionType.Authenticated,
        })
    } catch (err) {
        console.log(err)
        if (err === 'No current user') {
            Auth.federatedSignIn()
            return dispatch({ type: AuthActionType.NoUserFound })
        }
        return dispatch({ type: AuthActionType.Failed, error: err as string })
    }
}

export async function getAuthToken() {
    try {
        let session = await Auth.currentSession()
        let auth_token = session.getAccessToken().getJwtToken()
        return auth_token
    } catch (err) {
        if (err === 'No current user') {
            throw err
        }
    }
}
