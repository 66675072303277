import {
    format,
    isAfter,
    isWithinInterval,
    formatDistanceToNow,
    isSameDay,
    subMinutes,
    isBefore,
    isToday,
} from 'date-fns'
import React, { ReactNode, RefObject, useContext, useMemo } from 'react'
import { FunctionComponent, useEffect, useState } from 'react'
import {
    DeleteEvent,
    EventActionType,
    EventContext,
    LockEvent,
} from '../../store/events'
import { LockContext } from '../../store/locks'
import Styles from './Event.module.css'
import dropdownimg from '../../assets/svg/arrow.svg'
import editimg from '../../assets/svg/edit.svg'
import trashimg from '../../assets/svg/trash.svg'
import copyimg from '../../assets/svg/copy.svg'
import { ToastDef, ToastType } from '../Toast'
import {
    AddEvent,
    LockEventFormType,
} from '../AddOrModifyLockEvent/AddOrModifyLockEventModal'
import { API } from 'aws-amplify'
import { APIName } from '../../store/auth'
import { ClickAwayListener } from '@mui/material'
import { ModalView } from '../ModalView'
import { Button, ButtonType } from '../Button'

interface IProps {
    event: LockEvent
    getModalData: (newTitle: string, content: ReactNode) => void
    getToast: (toast: ToastDef | null) => void
    setIsModalShowing: (isShowing: boolean) => void
    dashboard?: boolean
    openLockId?: (lockID: string) => void
    mock?: boolean
}

export const Event: FunctionComponent<IProps> = ({
    event,
    getModalData,
    getToast,
    setIsModalShowing,
    dashboard,
    openLockId,
    mock,
}) => {
    const [, eventDispatch] = useContext(EventContext)
    const [isTextClamped, setIsTextClamped] = useState(false)
    const [dropdownOpen, setdropdownOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [globalLocks] = useContext(LockContext)
    const isPastEvent: boolean = useMemo(
        () => !isToday(event.end) && isBefore(event.end, new Date()),
        [event.end]
    )
    const [isInOperation, setIsInOperation] = useState(false)
    const [isCopyModalShowing, setIsCopyModalShowing] = useState(false)
    const [isEditModalShowing, setIsEditModalShowing] = useState(false)
    const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false)
    const notesref: RefObject<HTMLHeadingElement> = React.createRef()
    const { id, title, start, end, sponsor, leader, notes, locks } = event

    /* This is a function that applys a 300 millisecond timeout to another function what gets passed in. In this 
       case it is being used to check resize of window every second instead of every pixel for optimization */
    const debounce = (handleResize: Function, ms = 300) => {
        let timer: ReturnType<typeof setTimeout>
        return function (this: any, ...args: any[]) {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(() => handleResize.apply(this, args), ms)
        }
    }

    async function deleteEvent(event: DeleteEvent) {
        const deleteEventPath = '/event'
        const deleteEventData = {
            body: event,
        }
        let deleteResponse: DeleteEvent = {
            eventId: '',
            locationId: '',
        }
        let res = await API.del(
            APIName,
            deleteEventPath,
            deleteEventData
        ).catch((error) => {
            new Error(error)
        })

        deleteResponse = res.result
        const deleteEventResponse: DeleteEvent = {
            eventId: deleteResponse.eventId,
            locationId: deleteResponse.locationId,
        }
        setIsInOperation(!isInOperation)
        setIsDeleteModalShowing(false)
        getToast({
            type: ToastType.Success,
            contents: <div>Lock event successfully deleted.</div>,
        })
        eventDispatch({
            type: EventActionType.DeleteEvent,
            event: deleteEventResponse,
        })
    }

    const CheckForLineClamp = () => {
        if (notesref.current != null) {
            if (notesref.current.clientHeight < notesref.current.scrollHeight) {
                setIsTextClamped(true)
            } else {
                setIsTextClamped(false)
            }
        }
    }

    const CheckTextClamp = debounce(function CheckTextClamp() {
        CheckForLineClamp()
    }, 100)

    useEffect(() => {
        CheckForLineClamp()
        window.addEventListener('resize', CheckTextClamp)
        return () => {
            window.removeEventListener('resize', CheckTextClamp)
        }
    })

    const configureReadMoreModal = () => {
        getModalData(
            title,
            <>
                <h4 className={Styles['event-header']}>Notes</h4>
                <h3 className={Styles['modal-notes']}>{notes}</h3>
            </>
        )
        setIsModalShowing(true)
    }

    const configureDeleteModal = () => {
        setIsDeleteModalShowing(true)
        setMenuOpen(false)
    }

    const isCurrent = isWithinInterval(new Date(), { start, end })
    const endingSoon = isWithinInterval(new Date(), {
        start: subMinutes(end, 30),
        end,
    })
    const ended = isSameDay(new Date(), end) && isAfter(new Date(), end)
    var badge = <></>
    if (endingSoon) {
        badge = (
            <div className={Styles['badge']}>
                {formatDistanceToNow(end)} remaining
            </div>
        )
    } else if (ended) {
        badge = (
            <div className={`${Styles['badge']} ${Styles['badge-error']}`}>
                event over
            </div>
        )
    }

    const lockBadges = locks.map((lockId) => {
        const lockName = globalLocks.get(lockId)?.name
        if (openLockId) {
            return (
                <button
                    key={lockId}
                    className={
                        !dashboard && isPastEvent
                            ? `${Styles['locks-badge']} ${Styles['past-locks-badge']}`
                            : Styles['locks-badge'] 
                        }
                    onClick={() => {
                        if (openLockId) {
                            openLockId(lockId)
                        }
                    }}
                >
                <div className={dashboard ? ' underline ' : ''}>{lockName}</div>
                </button>
            )
        } else {
            return (
                <div
                    key={lockId}
                    className={
                        !dashboard && isPastEvent
                            ? `${Styles['locks-badge']} ${Styles['past-locks-badge']}`
                            : Styles['locks-badge']
                    }
                >
                    {lockName}  
                </div>
            )
        }
    })

    return (
        <>
            <div
                key={id}
                className={Styles['event-container']}
                data-testid={`event ${id}`}
            >
                {menuOpen && (
                    <ClickAwayListener
                        onClickAway={() => {
                            setMenuOpen(false)
                        }}
                    >
                        <div className="absolute mt-14 mr-6 py-4 right-0 top-0 text-sm leading-3 w-[8.45rem] bg-[#20203B] z-40">
                            <button
                                className="text-left px-6 h-10 w-full flex items-center hover:bg-[#404065] transition-colors "
                                onClick={() => {
                                    setIsEditModalShowing(true)
                                    setMenuOpen(false)
                                }}
                            >
                                <img
                                    src={editimg}
                                    className="inline-block mr-2 my-2"
                                    alt="edit"
                                />
                                <div className="pb-1">Edit</div>
                            </button>
                            <div className="mx-6 my-1 border-b border-[#C4C4C4]/25" />
                            <button
                                className="text-left px-6 h-10 w-full flex items-center hover:bg-[#404065] transition-colors"
                                onClick={() => {
                                    setIsCopyModalShowing(true)
                                    setMenuOpen(false)
                                }}
                            >
                                <img
                                    src={copyimg}
                                    className="inline-block mr-2 my-2"
                                    alt="edit"
                                />
                                <div className="pb-1">Copy</div>
                            </button>
                            <div className="mx-6 my-1 border-b border-[#C4C4C4]/25" />
                            {/* <button
                                className="text-left px-6 h-10 w-full flex items-center hover:bg-[#404065] transition-colors"
                                onClick={() => {
                                    setMenuOpen(false)
                                }}
                            >
                                <img
                                    src={calendarimg}
                                    className="inline-block mr-2 my-2"
                                    alt="edit"
                                />
                                <div className="pb-1">Add To Calendar</div>
                            </button>
                            <div className="mx-6 my-1 border-b border-[#C4C4C4]/25"/> */}
                            <button
                                className="text-left px-6 h-10 w-full flex items-center hover:bg-[#404065] transition-colors"
                                onClick={configureDeleteModal}
                            >
                                <img
                                    src={trashimg}
                                    className="inline-block mr-2 my-2"
                                    alt="edit"
                                />
                                <div className="pb-1">Delete</div>
                            </button>
                        </div>
                    </ClickAwayListener>
                )}
                <div
                    className={
                        !dashboard && isPastEvent
                            ? `${Styles['date-container']} ${Styles['past-date']}`
                            : Styles['date-container']
                    }
                >
                    <div className={Styles['date']}>
                        <h3 className={Styles['year']}>
                            {format(start, 'yyyy')}
                        </h3>
                        <h1 className={Styles['day']}>{format(start, 'd')}</h1>
                        <h2 className={Styles['month']}>
                            {format(start, 'MMM').toUpperCase()}
                        </h2>
                    </div>
                </div>
                <div
                    className={
                        !dashboard && isPastEvent
                            ? `${Styles['info-container']} ${Styles['past-info']}`
                            : Styles['info-container']
                    }
                >
                    <div className={Styles['event-title']}>
                        <h3 className={Styles['event-title-content']}>
                            {title}
                        </h3>
                        {isCurrent && dashboard ? (
                            <div className={Styles['current-marker']} />
                        ) : (
                            <></>
                        )}
                        {badge}
                        <div className="ml-auto flex h-min">
                            {lockBadges}
                            {isPastEvent ? (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    className="inline-block mx-2 my-2 fill-white hover:fill-cyan transition-colors cursor-pointer"
                                    onClick={() => {
                                        setIsCopyModalShowing(true)
                                    }}
                                >
                                    <path d="M6 0.25C2.82436 0.25 0.25 2.82436 0.25 6V13.1069C0.25 13.5211 0.585786 13.8569 1 13.8569C1.41421 13.8569 1.75 13.5211 1.75 13.1069V6C1.75 3.65279 3.65279 1.75 6 1.75H13.0129C13.4271 1.75 13.7629 1.41421 13.7629 1C13.7629 0.585786 13.4271 0.25 13.0129 0.25H6Z" />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.4026 3.79326C12.1616 3.43104 8.83843 3.43104 5.59748 3.79326C4.6742 3.89645 3.93227 4.62304 3.82344 5.55348C3.43906 8.83996 3.43906 12.16 3.82344 15.4465C3.93227 16.377 4.6742 17.1035 5.59748 17.2067C8.83843 17.569 12.1616 17.569 15.4026 17.2067C16.3258 17.1035 17.0678 16.377 17.1766 15.4465C17.561 12.16 17.561 8.83996 17.1766 5.55348C17.0678 4.62304 16.3258 3.89645 15.4026 3.79326ZM5.76409 5.28398C8.89431 4.93413 12.1057 4.93413 15.2359 5.28398C15.4733 5.3105 15.6599 5.49821 15.6867 5.72773C16.0576 8.89843 16.0576 12.1016 15.6867 15.2723C15.6599 15.5018 15.4733 15.6895 15.2359 15.716C12.1057 16.0659 8.89431 16.0659 5.76409 15.716C5.52674 15.6895 5.34013 15.5018 5.31329 15.2723C4.94245 12.1016 4.94245 8.89844 5.31329 5.72773C5.34013 5.49821 5.52674 5.3105 5.76409 5.28398Z"
                                    />
                                </svg>
                            ) : !dashboard ? (
                                <button
                                    className="p-2 w-min"
                                    onClick={() => {
                                        setMenuOpen(!menuOpen)
                                    }}
                                    data-testid="dropdown-button"
                                >
                                    <svg
                                        width="18"
                                        height="4"
                                        viewBox="0 0 18 4"
                                        fill={menuOpen ? 'cyan' : 'white'}
                                    >
                                        <path d="M2 0.25C1.0335 0.25 0.25 1.0335 0.25 2C0.25 2.9665 1.0335 3.75 2 3.75C2.9665 3.75 3.75 2.9665 3.75 2C3.75 1.0335 2.9665 0.25 2 0.25Z" />
                                        <path d="M7.25 2C7.25 1.0335 8.0335 0.25 9 0.25C9.9665 0.25 10.75 1.0335 10.75 2C10.75 2.9665 9.9665 3.75 9 3.75C8.0335 3.75 7.25 2.9665 7.25 2Z" />
                                        <path d="M14.25 2C14.25 1.0335 15.0335 0.25 16 0.25C16.9665 0.25 17.75 1.0335 17.75 2C17.75 2.9665 16.9665 3.75 16 3.75C15.0335 3.75 14.25 2.9665 14.25 2Z" />
                                    </svg>
                                </button>
                            ) : (
                                <button
                                    className={
                                        'lg:hidden rotate-90 transition-transform ' +
                                        (dropdownOpen && '-rotate-90')
                                    }
                                    onClick={() => {
                                        setdropdownOpen(!dropdownOpen)
                                    }}
                                >
                                    <img
                                        src={dropdownimg}
                                        alt="Arrow to expand dropdown"
                                    ></img>
                                </button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${Styles['event-info']} ${
                            dropdownOpen || !dashboard
                                ? ''
                                : Styles['hide-details']
                        }`}
                    >
                        <div className={Styles['event-time']}>
                            <h4 className={Styles['event-header']}>Time</h4>
                            <h4 className={Styles['event-body']}>
                                {format(start, 'h:mmaaa')} -{' '}
                                {format(end, 'h:mmaaa')}
                            </h4>
                        </div>
                        <div className="m-0 lg:m-2" />
                        <div className={Styles['event-sponsor']}>
                            <h4 className={Styles['event-header']}>
                                Event Sponsor
                            </h4>
                            <h4 className={Styles['event-body']}>{sponsor}</h4>
                        </div>
                        <div className="m-0 lg:m-2" />
                        <div className={Styles['event-leader']}>
                            <h4 className={Styles['event-header']}>
                                Designated Leader
                            </h4>
                            <h4 className={Styles['event-body']}>{leader}</h4>
                        </div>
                        <div className="m-0 lg:m-2" />
                        {notes && (
                            <div className={Styles['event-notes']}>
                                <h4 className={Styles['event-header']}>
                                    Notes
                                </h4>
                                <div className={Styles['event-notes-body']}>
                                    <div
                                        ref={notesref}
                                        className={Styles['event-body']}
                                    >
                                        {notes}
                                    </div>
                                    {isTextClamped && (
                                        <button
                                            className={Styles['read-more']}
                                            onClick={configureReadMoreModal}
                                            data-testid={'read-more'}
                                        >
                                            more
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className={`flex gap-2 lg:hidden ${Styles['event-locks']}`}
                        >
                            {lockBadges}
                        </div>
                    </div>
                </div>
            </div>
            {isCopyModalShowing && (
                <AddEvent
                    modalOpen={isCopyModalShowing}
                    setModalOpen={setIsCopyModalShowing}
                    modalType={LockEventFormType.COPY}
                    lockEvent={event}
                    getToast={getToast}
                ></AddEvent>
            )}
            {isEditModalShowing && (
                <AddEvent
                    modalOpen={isEditModalShowing}
                    setModalOpen={setIsEditModalShowing}
                    modalType={LockEventFormType.EDIT}
                    lockEvent={event}
                    getToast={getToast}
                ></AddEvent>
            )}
            {}
            {isDeleteModalShowing ? (
                <ModalView
                    title="Delete Lock Event"
                    isModalShowing={isDeleteModalShowing}
                    setIsModalShowing={setIsDeleteModalShowing}
                >
                    <>
                        <div className="w-full flex justify-center">
                            <h4 className="text-center text-lg my-8 w-56  sm:w-72">
                                Are you sure you want to delete this lock event?
                            </h4>
                        </div>
                        <div className="flex justify-center">
                            <Button
                                onClick={() => {
                                    setIsDeleteModalShowing(false)
                                }}
                                type={ButtonType.Secondary}
                                text="Cancel"
                            />
                            <div className="px-2" />
                            <Button
                                onClick={async () => {
                                    if (!mock) {
                                        setIsInOperation(!isInOperation)
                                        deleteEvent({
                                            eventId: id,
                                            locationId: '1',
                                        })
                                    } else {
                                        getToast({
                                            type: ToastType.Success,
                                            contents: (
                                                <div>
                                                    Lock event successfully
                                                    deleted.
                                                </div>
                                            ),
                                        })
                                    }
                                }}
                                type={ButtonType.Warning}
                                submitting={isInOperation}
                                text="Delete Lock Event"
                                testid="delete-button"
                            />
                        </div>
                    </>
                </ModalView>
            ) : (
                <></>
            )}
        </>
    )
}
