
export enum IconType {
    Dashboard,
    Lock,
    Admin,
    LogOut,
    Hamburger,
}
export function renderIcon(type: IconType, extraClasses: string) {
    switch (type) {
        case IconType.Dashboard:
            return (
                <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    className={'fill ' + extraClasses}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.25231 1.35275C4.2925 1.24619 3.29895 1.24619 2.33914 1.35275C2.10592 1.37864 1.92199 1.56208 1.89576 1.78487C1.78114 2.75833 1.78114 3.74174 1.89576 4.71519C1.92199 4.93799 2.10592 5.12143 2.33914 5.14732C3.29895 5.25388 4.2925 5.25388 5.25231 5.14732C5.48554 5.12143 5.66946 4.93799 5.69569 4.71519C5.81031 3.74174 5.81031 2.75833 5.69569 1.78487C5.66946 1.56208 5.48554 1.37864 5.25231 1.35275ZM2.21334 0.234707C3.25676 0.118867 4.33469 0.118867 5.37811 0.234707C6.12623 0.317763 6.7321 0.902753 6.82057 1.65419C6.94542 2.71447 6.94542 3.7856 6.82057 4.84588C6.7321 5.59731 6.12623 6.1823 5.37811 6.26536C4.33469 6.3812 3.25676 6.3812 2.21334 6.26536C1.46523 6.1823 0.859354 5.59731 0.770878 4.84588C0.646036 3.7856 0.646036 2.71447 0.770878 1.65419C0.859354 0.902753 1.46523 0.317763 2.21334 0.234707Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.25231 8.85275C4.2925 8.74619 3.29895 8.74619 2.33914 8.85275C2.10592 8.87864 1.92199 9.06208 1.89576 9.28487C1.78114 10.2583 1.78114 11.2417 1.89576 12.2152C1.92199 12.438 2.10592 12.6214 2.33914 12.6473C3.29895 12.7539 4.2925 12.7539 5.25231 12.6473C5.48554 12.6214 5.66946 12.438 5.69569 12.2152C5.81031 11.2417 5.81031 10.2583 5.69569 9.28487C5.66946 9.06208 5.48554 8.87864 5.25231 8.85275ZM2.21334 7.73471C3.25676 7.61887 4.33469 7.61887 5.37811 7.73471C6.12623 7.81776 6.7321 8.40275 6.82057 9.15419C6.94542 10.2145 6.94542 11.2856 6.82057 12.3459C6.7321 13.0973 6.12623 13.6823 5.37811 13.7654C4.33469 13.8812 3.25676 13.8812 2.21334 13.7654C1.46523 13.6823 0.859354 13.0973 0.770878 12.3459C0.646036 11.2856 0.646036 10.2145 0.770878 9.15419C0.859354 8.40275 1.46523 7.81776 2.21334 7.73471Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.8026 1.35275C11.8428 1.24619 10.8493 1.24619 9.88947 1.35275C9.65624 1.37864 9.47232 1.56208 9.44609 1.78487C9.33147 2.75833 9.33147 3.74174 9.44609 4.71519C9.47232 4.93799 9.65624 5.12143 9.88947 5.14732C10.8493 5.25388 11.8428 5.25388 12.8026 5.14732C13.0359 5.12143 13.2198 4.93799 13.246 4.71519C13.3606 3.74174 13.3606 2.75833 13.246 1.78487C13.2198 1.56208 13.0359 1.37864 12.8026 1.35275ZM9.76367 0.234707C10.8071 0.118867 11.885 0.118867 12.9284 0.234707C13.6766 0.317763 14.2824 0.902753 14.3709 1.65419C14.4957 2.71447 14.4957 3.7856 14.3709 4.84588C14.2824 5.59731 13.6766 6.1823 12.9284 6.26536C11.885 6.3812 10.8071 6.3812 9.76367 6.26536C9.01555 6.1823 8.40968 5.59731 8.32121 4.84588C8.19636 3.7856 8.19636 2.71447 8.32121 1.65419C8.40968 0.902753 9.01555 0.317763 9.76367 0.234707Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.8026 8.85275C11.8428 8.74619 10.8493 8.74619 9.88947 8.85275C9.65624 8.87864 9.47232 9.06208 9.44609 9.28487C9.33147 10.2583 9.33147 11.2417 9.44609 12.2152C9.47232 12.438 9.65624 12.6214 9.88947 12.6473C10.8493 12.7539 11.8428 12.7539 12.8026 12.6473C13.0359 12.6214 13.2198 12.438 13.246 12.2152C13.3606 11.2417 13.3606 10.2583 13.246 9.28487C13.2198 9.06208 13.0359 8.87864 12.8026 8.85275ZM9.76367 7.73471C10.8071 7.61887 11.885 7.61887 12.9284 7.73471C13.6766 7.81776 14.2824 8.40275 14.3709 9.15419C14.4957 10.2145 14.4957 11.2856 14.3709 12.3459C14.2824 13.0973 13.6766 13.6823 12.9284 13.7654C11.885 13.8812 10.8071 13.8812 9.76367 13.7654C9.01555 13.6823 8.40968 13.0973 8.32121 12.3459C8.19636 11.2856 8.19636 10.2145 8.32121 9.15419C8.40968 8.40275 9.01555 7.81776 9.76367 7.73471Z"
                    />
                </svg>
            )
        case IconType.Lock:
            return (
                <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    className={'stroke ' + extraClasses}
                >
                    <path
                        d="M4.63239 11C4.63239 10.3786 5.13944 9.87496 5.76493 9.87496C6.39042 9.87496 6.89748 10.3786 6.89748 11C6.89748 11.6213 6.39042 12.125 5.76493 12.125C5.13944 12.125 4.63239 11.6213 4.63239 11Z"
                        fill="#BABABA"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.45914 6.94782L2.22097 4.81859C2.19043 4.5456 2.19043 4.2701 2.22097 3.99712L2.23816 3.84344C2.42049 2.2134 3.71054 0.923657 5.35031 0.732029C5.62576 0.699838 5.90407 0.699838 6.17952 0.732029C7.81929 0.923657 9.10934 2.2134 9.29167 3.84344L9.30886 3.99712C9.3394 4.2701 9.3394 4.5456 9.30886 4.81859L9.0707 6.94781L9.5891 6.98892C10.4066 7.05375 11.0741 7.66418 11.2066 8.4681C11.483 10.1448 11.483 11.8551 11.2066 13.5318C11.0741 14.3357 10.4066 14.9462 9.5891 15.011L8.45948 15.1006C6.66598 15.2428 4.86393 15.2428 3.07043 15.1006L1.94081 15.011C1.12331 14.9462 0.455784 14.3357 0.323264 13.5318C0.0468677 11.8551 0.0468677 10.1448 0.323264 8.4681C0.455784 7.66418 1.12331 7.05375 1.94081 6.98892L2.45914 6.94782ZM5.48264 1.84932C5.67018 1.82741 5.85966 1.82741 6.04719 1.84932C7.16361 1.97979 8.04192 2.85789 8.16605 3.96768L8.18324 4.12135C8.20454 4.31177 8.20454 4.50394 8.18324 4.69435L7.94075 6.86219C6.4917 6.7695 5.03814 6.7695 3.58908 6.86219L3.34659 4.69435C3.32529 4.50394 3.32529 4.31176 3.34659 4.12135L3.36378 3.96768C3.48792 2.85789 4.36623 1.97979 5.48264 1.84932ZM8.36935 8.02077C6.63584 7.88331 4.89407 7.88331 3.16056 8.02077L2.03093 8.11035C1.73275 8.134 1.48927 8.35666 1.44093 8.64989C1.18438 10.2062 1.18438 11.7937 1.44093 13.35C1.48927 13.6433 1.73275 13.8659 2.03093 13.8896L3.16056 13.9791C4.89406 14.1166 6.63585 14.1166 8.36935 13.9791L9.49898 13.8896C9.79716 13.8659 10.0406 13.6433 10.089 13.35C10.3455 11.7937 10.3455 10.2062 10.089 8.64989C10.0406 8.35666 9.79716 8.134 9.49897 8.11035L8.36935 8.02077Z"
                        fill="#BABABA"
                    />
                </svg>
            )
        case IconType.Admin:
            return (
                <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    className={'stroke ' + extraClasses}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.21189 1.94784C6.91238 1.76869 6.5377 1.76869 6.23818 1.94784L5.84836 2.18101C4.80548 2.80479 3.63471 3.18756 2.42273 3.30099L2.17714 3.32397C2.08018 3.33305 2.00608 3.41391 2.00608 3.51065V4.74356C2.00608 6.62422 2.77473 8.42429 4.13594 9.73142L6.59384 12.0917C6.66701 12.1619 6.78307 12.1619 6.85623 12.0917L9.31414 9.73142C10.6753 8.42429 11.444 6.62422 11.444 4.74356V3.51065C11.444 3.4139 11.3699 3.33305 11.2729 3.32397L11.0273 3.30099C9.81536 3.18756 8.64459 2.80479 7.60172 2.18101L7.21189 1.94784ZM5.65396 0.984076C6.31289 0.589944 7.13718 0.589943 7.79612 0.984076L8.18595 1.21724C9.0833 1.75399 10.0907 2.08335 11.1336 2.18095L11.3792 2.20393C12.0579 2.26745 12.5765 2.83347 12.5765 3.51065V4.74356C12.5765 6.92919 11.6833 9.02117 10.1013 10.5403L7.6434 12.9005C7.13125 13.3923 6.31883 13.3923 5.80668 12.9005L3.34877 10.5403C1.76682 9.02116 0.873535 6.92919 0.873535 4.74356V3.51065C0.873535 2.83347 1.39219 2.26745 2.07091 2.20393L2.3165 2.18095C3.35937 2.08335 4.36678 1.75399 5.26413 1.21725L5.65396 0.984076Z"
                        fill="#BABABA"
                    />
                </svg>
            )
        case IconType.LogOut:
            return (
                <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    className={'fill ' + extraClasses}
                >
                    <path d="M7 10.6875C6.68934 10.6875 6.4375 10.9393 6.4375 11.25C6.4375 11.5607 6.68934 11.8125 7 11.8125H11.5C12.2249 11.8125 12.8125 11.2249 12.8125 10.5V1.5C12.8125 0.775127 12.2249 0.1875 11.5 0.1875H7C6.68934 0.1875 6.4375 0.439341 6.4375 0.750001C6.4375 1.06066 6.68934 1.3125 7 1.3125L11.5 1.3125C11.6036 1.3125 11.6875 1.39645 11.6875 1.5L11.6875 10.5C11.6875 10.6036 11.6036 10.6875 11.5 10.6875H7Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.87731 7.77391C9.39508 7.77391 9.81481 7.35418 9.81481 6.83641V5.15603C9.81481 4.63826 9.39507 4.21853 8.87731 4.21853H5.41786C5.41297 4.16346 5.40785 4.1084 5.4025 4.05336L5.36198 3.63645C5.30015 3.00037 4.6247 2.61943 4.04839 2.89563C2.82271 3.48303 1.71314 4.28684 0.773016 5.2684L0.698617 5.34608C0.350461 5.70959 0.350461 6.28284 0.698616 6.64635L0.773016 6.72403C1.71314 7.70559 2.82272 8.50941 4.04838 9.0968C4.6247 9.373 5.30015 8.99206 5.36198 8.35598L5.4025 7.93907C5.40785 7.88403 5.41297 7.82898 5.41786 7.77391H8.87731ZM4.89633 6.64891C4.69656 6.64891 4.51994 6.75338 4.42006 6.91198C4.37142 6.98922 4.34098 7.0793 4.33492 7.1764C4.32132 7.39456 4.30393 7.61254 4.28277 7.83024L4.27101 7.95125C3.49398 7.5508 2.77237 7.05212 2.12378 6.46793C1.95552 6.31637 1.79216 6.15906 1.63403 5.99622C2.40169 5.20568 3.29239 4.54552 4.27101 4.04118L4.28277 4.16219C4.30393 4.3799 4.32132 4.59788 4.33492 4.81604C4.35341 5.11253 4.59927 5.34353 4.89633 5.34353H8.68981V6.64891H4.89633Z"
                    />
                </svg>
            )
        case IconType.Hamburger:
            return (
                <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    className={'fill ' + extraClasses}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H1C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H1C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75H15C15.4142 1.75 15.75 1.41421 15.75 1Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.75 11C15.75 10.5858 15.4142 10.25 15 10.25H1C0.585786 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585786 11.75 1 11.75H15C15.4142 11.75 15.75 11.4142 15.75 11Z"
                    />
                </svg>
            )
    }
}