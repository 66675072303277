import { createContext } from 'react'

export type Location = {
    locationId: string
    name: string
}

export type LocationState = Map<string, Location>

export const default_locations: LocationState = new Map([
    ['1', { locationId: '1', name: 'Chicago' }],
    ['2', { locationId: '2', name: 'Seattle' }],
    ['3', { locationId: '3', name: 'Toronto' }],
])

export enum LocationActionType {
    Load,
}

export type LocationAction = {
    type: LocationActionType.Load
    locations: Location[]
}

export const LocationContext = createContext<
    [LocationState, React.Dispatch<LocationAction>]
>([default_locations, () => {}])

export function LocationReducer(
    state: LocationState,
    action: LocationAction
): LocationState {
    switch (action.type) {
        case LocationActionType.Load:
            action.locations.forEach((location) => {
                state.set(location.locationId, location)
            })
            break
    }
    return state
}

export function LocationList(state: LocationState) {
    let ret: Location[] = []
    state.forEach((loc) => {
        ret.push(loc)
    })
    return ret
}
