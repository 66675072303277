import { FunctionComponent } from 'react'
import { LockDisplayState } from '../../store/locks'

type DashboardIconProps = {
    state: LockDisplayState
    x?: number
    y?: number
    classes?: string
    width?: number
    height?: number
}

export const DashboardIcon: FunctionComponent<DashboardIconProps> = ({
    state,
    x,
    y,
    width,
    height,
    classes = '',
}) => {
    if (state === LockDisplayState.Unlocked) {
        return (
            <svg
                viewBox="0 0 54 69"
                width={width}
                height={height}
                className={classes}
                x={x}
                y={y}
                fill="url(#enabled_gradient)"
            >
                <path d="M21.7499 48.9999C21.7499 46.1004 24.1004 43.7499 26.9999 43.7499C29.8994 43.7499 32.2499 46.1004 32.2499 48.9999C32.2499 51.8994 29.8994 54.2499 26.9999 54.2499C24.1004 54.2499 21.7499 51.8994 21.7499 48.9999Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.3367 7.01733C20.1499 6.69353 20.9983 6.4662 21.8645 6.33999C27.021 5.58865 32.0143 8.49306 33.9105 13.3467L34.1731 14.0187C34.4985 14.8515 34.7306 15.7177 34.8652 16.6016L35.6234 21.5797L40.8135 20.7892L40.0553 15.8111C39.8623 14.544 39.5296 13.3021 39.0631 12.1082L38.8006 11.4362C36.0154 4.30725 28.6812 0.0412958 21.1075 1.14485C19.8353 1.33023 18.5892 1.66413 17.3947 2.13972C10.2839 4.97086 6.0653 12.3323 7.2177 19.8988L7.32634 20.6121C7.51933 21.8792 7.85209 23.1211 8.31853 24.315L10.608 30.1751L9.27288 30.2817C5.48333 30.5843 2.38896 33.4329 1.77466 37.1846C0.493408 45.0093 0.493408 52.9904 1.77466 60.8152C2.38896 64.5669 5.48333 67.4155 9.27288 67.7181L14.5093 68.1361C22.8232 68.7998 31.1767 68.7998 39.4906 68.1361L44.727 67.7181C48.5166 67.4155 51.6109 64.5669 52.2252 60.8152C53.5065 52.9904 53.5065 45.0093 52.2252 37.1846C51.6109 33.4329 48.5166 30.5843 44.727 30.2817L39.4905 29.8637C31.6991 29.2417 23.8728 29.2026 16.077 29.7464L13.2086 22.4045C12.8832 21.5717 12.6511 20.7055 12.5165 19.8216L12.4078 19.1083C11.6233 13.9568 14.4954 8.94487 19.3367 7.01733ZM39.0728 35.097C31.037 34.4555 22.9629 34.4555 14.9271 35.097L9.69066 35.5151C8.30841 35.6254 7.17973 36.6645 6.95566 38.0329C5.76641 45.2959 5.76641 52.7039 6.95566 59.9669C7.17973 61.3353 8.30841 62.3744 9.69067 62.4847L14.9271 62.9027C22.9629 63.5443 31.037 63.5443 39.0728 62.9027L44.3092 62.4847C45.6915 62.3744 46.8202 61.3353 47.0442 59.9669C48.2335 52.7039 48.2335 45.2959 47.0442 38.0329C46.8202 36.6645 45.6915 35.6254 44.3092 35.5151L39.0728 35.097Z"
                />
                <defs>
                    <linearGradient
                        id="enabled_gradient"
                        x1="26.9999"
                        y1="0.971924"
                        x2="26.9999"
                        y2="68.6339"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.265625" stopColor="#2FD89B" />
                        <stop offset="1" stopColor="#2492E1" />
                    </linearGradient>
                </defs>
            </svg>
        )
    } else if (state === LockDisplayState.Unlocking) {
        return (
            <svg
                viewBox="0 0 100 130"
                width={width}
                height={height}
                className={classes}
                x={x}
                y={y}
            >
                <path
                    d="M39.9999 91.6667C39.9999 86.1439 44.4771 81.6667 49.9999 81.6667C55.5228 81.6667 59.9999 86.1439 59.9999 91.6667C59.9999 97.1896 55.5228 101.667 49.9999 101.667C44.4771 101.667 39.9999 97.1896 39.9999 91.6667Z"
                    fill="white"
                    fillOpacity="0.16"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="m71.353 35.617c0.188-1.6926 0.188-3.4008 0-5.0933l-0.1518-1.366c-1.0961-9.8648-8.8512-17.67-18.709-18.83-1.6559-0.1949-3.329-0.1949-4.9849 0-9.8575 1.1597-17.613 8.965-18.709 18.83l-0.1518 1.366c-0.188 1.6925-0.188 3.4007 0 5.0933l2.1411 19.27c12.795-0.8239 25.629-0.8239 38.424 0l9.977 0.7611 4.5773 0.3654c7.2182 0.5762 13.112 6.0022 14.282 13.148 2.4408 14.904 2.4408 30.106 0 45.011-1.1701 7.146-7.0641 12.572-14.282 13.148l-9.9742 0.797c-15.836 1.264-31.747 1.264-47.583 0l-9.9742-0.797c-7.2182-0.576-13.112-6.002-14.282-13.148-2.4405-14.904-2.4405-30.106 0-45.011 1.1701-7.146 7.0641-12.572 14.282-13.148l4.5766-0.3654-2.1029-18.926c-0.2696-2.4265-0.2696-4.8754 0-7.302l0.1518-1.3659c1.6099-14.489 13.001-25.954 27.479-27.657 2.4322-0.28614 4.8895-0.28614 7.3217 1e-6 14.479 1.7034 25.869 13.168 27.479 27.657l0.1518 1.3659c0.2696 2.4266 0.2696 4.8755 0 7.302zm1.6432 29.568c-15.306-1.2219-30.685-1.2219-45.992 0l-9.9742 0.7963c-2.6328 0.2101-4.7827 2.1893-5.2095 4.7958-2.2652 13.834-2.2652 27.945 0 41.779 0.4268 2.607 2.5767 4.586 5.2095 4.796l9.9742 0.796c15.306 1.222 30.686 1.222 45.992 0l9.9742-0.796c2.6329-0.21 4.7828-2.189 5.2096-4.796 2.2652-13.834 2.2652-27.945 0-41.779-0.4268-2.6065-2.5767-4.5857-5.2096-4.7958z"
                    fill="white"
                    fillOpacity="0.16"
                />
            </svg>
        )
    } else if (
        state === LockDisplayState.Locked ||
        state === LockDisplayState.Locking
    ) {
        return (
            <svg
                viewBox="0 0 54 69"
                width={width}
                height={height}
                className={
                    classes +
                    (state === LockDisplayState.Locked
                        ? ' fill-white'
                        : ' fill-[#FFFFFF80]')
                }
                x={x}
                y={y}
            >
                <path d="M21.75 49C21.75 46.1005 24.1005 43.75 27 43.75C29.8995 43.75 32.25 46.1005 32.25 49C32.25 51.8995 29.8995 54.25 27 54.25C24.1005 54.25 21.75 51.8995 21.75 49Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6758 30.09L10.5717 20.1536C10.4302 18.8797 10.4302 17.594 10.5717 16.3201L10.6514 15.6029C11.4966 7.99607 17.4767 1.97727 25.078 1.083C26.3549 0.932781 27.645 0.932781 28.9219 1.083C36.5231 1.97727 42.5032 7.99608 43.3484 15.6029L43.4281 16.3201C43.5696 17.594 43.5696 18.8797 43.4281 20.1536L42.3241 30.09L44.7272 30.2818C48.5167 30.5844 51.6111 33.433 52.2254 37.1847C53.5066 45.0095 53.5066 52.9906 52.2254 60.8153C51.6111 64.567 48.5167 67.4156 44.7272 67.7182L39.4907 68.1362C31.1769 68.7999 22.8233 68.7999 14.5095 68.1362L9.27303 67.7182C5.48349 67.4156 2.38912 64.567 1.77481 60.8153C0.49356 52.9906 0.49356 45.0095 1.77481 37.1847C2.38912 33.433 5.48348 30.5844 9.27303 30.2818L11.6758 30.09ZM25.6914 6.29704C26.5607 6.19477 27.4391 6.19477 28.3084 6.29704C33.4836 6.90589 37.5551 11.0037 38.1305 16.1827L38.2102 16.8998C38.3089 17.7884 38.3089 18.6852 38.2102 19.5738L37.0861 29.6904C30.369 29.2579 23.6309 29.2579 16.9137 29.6904L15.7896 19.5738C15.6909 18.6852 15.6909 17.7884 15.7896 16.8998L15.8693 16.1827C16.4448 11.0037 20.5162 6.90589 25.6914 6.29704ZM39.0729 35.0972C31.0371 34.4556 22.963 34.4556 14.9273 35.0972L9.69082 35.5152C8.30857 35.6255 7.17988 36.6646 6.95582 38.033C5.76656 45.296 5.76656 52.704 6.95582 59.967C7.17988 61.3354 8.30857 62.3745 9.69082 62.4848L14.9273 62.9029C22.963 63.5444 31.0372 63.5444 39.0729 62.9029L44.3094 62.4848C45.6916 62.3745 46.8203 61.3354 47.0444 59.967C48.2336 52.704 48.2336 45.296 47.0444 38.033C46.8203 36.6646 45.6916 35.6255 44.3094 35.5152L39.0729 35.0972Z"
                />
            </svg>
        )
    } else {
        return (
            <svg
                width={width}
                height={height}
                className={classes + ' fill-white'}
                x={x}
                y={y}
                viewBox="0 0 118 118"
            >
                <path
                    d="M59.0002 67.3333C56.2387 67.3333 54.0002 65.0947 54.0002 62.3333L54.0002 28.9999C54.0002 26.2385 56.2387 23.9999 59.0002 23.9999C61.7616 23.9999 64.0002 26.2385 64.0002 28.9999L64.0002 62.3333C64.0002 65.0947 61.7616 67.3333 59.0002 67.3333Z"
                    fill="white"
                />
                <path
                    d="M59.0002 78.9999C55.3183 78.9999 52.3335 81.9847 52.3335 85.6666C52.3335 89.3485 55.3183 92.3333 59.0002 92.3333C62.6821 92.3333 65.6668 89.3485 65.6668 85.6666C65.6668 81.9847 62.6821 78.9999 59.0002 78.9999Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M117.333 58.9999C117.333 91.2165 91.2168 117.333 59.0002 117.333C26.7836 117.333 0.666832 91.2165 0.666832 58.9999C0.666832 26.7833 26.7836 0.666588 59.0002 0.666588C91.2168 0.666588 117.333 26.7833 117.333 58.9999ZM59.0002 107.333C85.6939 107.333 107.333 85.6937 107.333 58.9999C107.333 32.3062 85.6939 10.6666 59.0002 10.6666C32.3064 10.6666 10.6668 32.3062 10.6668 58.9999C10.6668 85.6937 32.3064 107.333 59.0002 107.333Z"
                    fill="white"
                />
            </svg>
        )
    }
}
