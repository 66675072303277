import {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    Autocomplete,
    autocompleteClasses,
    TextField,
    ThemeProvider,
} from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import {
    DatePicker,
    LocalizationProvider,
    TimePicker,
} from '@mui/x-date-pickers'
import { CalendarIcon, DropdownIcon, SearchIcon } from './SvgIcons'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import isAfter from 'date-fns/isAfter'
import { ModalView } from '../ModalView'
import './AddOrModifyLockEvent.css'
import { Theme } from './CustomTheme'
import { ErrorMessage } from './ErrorMessage'
import {
    convertResponseToLockEventList,
    EventActionType,
    EventContext,
    EventRequestData,
    LockEvent,
    UserCredentials,
} from '../../store/events'
import {
    GetUserEmailWithName,
    getUsers,
    LeaderArray,
    SponsorArray,
    User,
    UserContext,
} from '../../store/users'
import { isBefore } from 'date-fns'
import { API } from 'aws-amplify'
import { APIName, getUsernameAndEmail } from '../../store/auth'
import { Button, ButtonType } from '../Button'
import { ToastDef, ToastType } from '../Toast'

export const enum LockEventFormType {
    EDIT,
    COPY,
    ADD,
}
interface IRecurrenceList {
    label: string
}

async function putEvent(eventData: EventRequestData) {
    const putEventPath = '/event'
    const putRequestData = {
        body: eventData,
    }
    let responseList: [] = []
    await API.put(APIName, putEventPath, putRequestData)
        .then((response) => {
            responseList = response.result
        })
        .catch((error) => {
            new Error(error)
        })
    return responseList
}
interface AddEventProps {
    modalOpen: boolean
    setModalOpen: (isShowing: boolean) => void
    modalType: LockEventFormType
    lockEvent?: LockEvent
    mock?: boolean
    getToast: (toast: ToastDef | null) => void
}
export const AddEvent: FunctionComponent<AddEventProps> = ({
    modalOpen,
    setModalOpen,
    modalType,
    lockEvent,
    mock,
    getToast,
}) => {
    type FormInputs = {
        repeatEvent: string
        eventName: string
        eventDate: Date | null
        startTime: Date | null
        endTime: Date | null
        eventSponsor: string
        designatedLeader: string
        notes: string
    }
    const defaultFormValues: FormInputs = {
        repeatEvent: '',
        eventName: '',
        eventDate: null,
        startTime: null,
        endTime: null,
        eventSponsor: '',
        designatedLeader: '',
        notes: '',
    }
    const {
        control,
        formState: { errors, isValid, isSubmitted },
        handleSubmit,
        reset,
        getValues,
        setValue,
        trigger,
    } = useForm<FormInputs>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: defaultFormValues,
        shouldFocusError: true,
    })

    const getTitle = (modalType: LockEventFormType) => {
        if (modalType === LockEventFormType.COPY) {
            return 'Copy Lock Event'
        }
        if (modalType === LockEventFormType.ADD) {
            return 'Add New Lock Event'
        }
        if (modalType === LockEventFormType.EDIT) {
            return 'Edit Lock Event'
        }
        return ''
    }
    const getToastText = (modalType: LockEventFormType) => {
        if (modalType === LockEventFormType.COPY) {
            return <div>Lock event successfully copied.</div>
        }
        if (modalType === LockEventFormType.ADD) {
            return <div>Lock event successfully added.</div>
        }
        if (modalType === LockEventFormType.EDIT) {
            return <div>Lock event successfully edited.</div>
        }
        return <div></div>
    }
    const getSubmitButtonText = (modalType: LockEventFormType) => {
        if (
            modalType === LockEventFormType.COPY ||
            modalType === LockEventFormType.EDIT
        ) {
            return 'Save'
        }
        if (modalType === LockEventFormType.ADD) {
            return 'Create Lock Event'
        }
        return ''
    }

    const [people, userDispatch] = useContext(UserContext)
    useEffect(() => {
        if (!mock) {
            getUsers(people, userDispatch)
        }
    }, [people, userDispatch, mock])

    const isPersonEqual = (left: User, right: User) => {
        return left.email === right.email
    }

    const onCancel = (e: { preventDefault: () => void }) => {
        setModalOpen(false)
        reset()
    }
    function convertTimesRepeated(repeatEvent: string): number | null {
       
        if ((modalType === LockEventFormType.EDIT)) {
            return null;
        }
        return recurrenceList.findIndex((searchTerm) => {
            return searchTerm.label === repeatEvent
        })
    }
    const ConvertToEventRequestData = async () => {
        const values = getValues()
        let eventId = null
        let timesRepeated = null as number | null
        let deviceIDs = [] as string[]

        values.startTime?.setDate(
            values.eventDate ? values.eventDate.getDate() : new Date().getDate()
        )
        values.endTime?.setDate(
            values.eventDate ? values.eventDate.getDate() : new Date().getDate()
        )
        values.startTime?.setMonth(
            values.eventDate
                ? values.eventDate.getMonth()
                : new Date().getMonth()
        )
        values.endTime?.setMonth(
            values.eventDate
                ? values.eventDate.getMonth()
                : new Date().getMonth()
        )
        values.startTime?.setFullYear(
            values.eventDate
                ? values.eventDate.getFullYear()
                : new Date().getFullYear()
        )
        values.endTime?.setFullYear(
            values.eventDate
                ? values.eventDate.getFullYear()
                : new Date().getFullYear()
        )
        if (lockEvent != null) {
            if (modalType === LockEventFormType.EDIT) {
                eventId = lockEvent.id
            }
            lockEvent.locks.forEach((lock) => {
                deviceIDs.push(lock)
            })
        } else {
            deviceIDs.push('1') //default fridge id 1
        }
        if (
            modalType === LockEventFormType.COPY ||
            modalType === LockEventFormType.ADD
        ) {
            timesRepeated = convertTimesRepeated(values.repeatEvent)
        }
        const designatedLeaderEmail = GetUserEmailWithName(
            people,
            values.designatedLeader
        )
        const leaderData = {
            name: values.designatedLeader,
            email: designatedLeaderEmail,
        } as UserCredentials

        const eventSponsorEmail = GetUserEmailWithName(
            people,
            values.eventSponsor
        )
        const sponsorData = {
            name: values.eventSponsor,
            email: eventSponsorEmail,
        } as UserCredentials
        const currentUser = (await getUsernameAndEmail()) as UserCredentials

        return {
            timesRepeated: timesRepeated,
            eventId: eventId,
            locationId: '1',
            deviceIds: deviceIDs,
            leader: leaderData,
            sponsor: sponsorData,
            creator: currentUser,
            name: values.eventName,
            notes: values.notes,
            startTime: values.startTime?.toUTCString(),
            endTime: values.endTime?.toUTCString(),
        } as EventRequestData
    }
    const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
        const putEventData = await ConvertToEventRequestData()
        const responseData = await putEvent(putEventData)
        let EventList: LockEvent[] =
            convertResponseToLockEventList(responseData)
        if (
            modalType === LockEventFormType.ADD ||
            modalType === LockEventFormType.COPY
        ) {
            eventDispatch({
                type: EventActionType.AddNewEvents,
                newEvents: EventList,
            })
        }
        if (lockEvent != null && modalType === LockEventFormType.EDIT) {
            if (EventList !== undefined) {
                lockEvent.end = EventList[0].end as Date
                lockEvent.start = EventList[0].start as Date
                lockEvent.title = EventList[0].title as string
                lockEvent.notes = EventList[0].notes as string
                lockEvent.sponsor = EventList[0].sponsor as string
                lockEvent.leader = EventList[0].leader as string
            }
        }
        getToast({
            type: ToastType.Success,
            contents: getToastText(modalType),
        })
        setModalOpen(false)
    }
    const createRepeatWeekList = (totalWeeks: number) => {
        let weekList = []
        weekList[0] = { label: `Do not repeat` }

        for (let weekNum = 1; weekNum <= totalWeeks; weekNum++) {
            weekList.push({ label: `Repeat for ${weekNum} weeks` })
            if (weekNum === 1) {
                weekList[weekNum].label = weekList[weekNum].label.replace(
                    'weeks',
                    'week'
                )
            }
        }
        return weekList
    }

    const recurrenceList: IRecurrenceList[] = useMemo(
        () => createRepeatWeekList(12),
        []
    )
    const [, eventDispatch] = useContext(EventContext)
    // state management functions for Date and Time pickers
    const [isDateOpen, setDateOpen] = useState(false)
    const [isStartOpen, setStartOpen] = useState(false)
    const [isEndOpen, setEndOpen] = useState(false)

    //state management functions for Event Object
    const [isInitalSetup, setIsInitialSetup] = useState(true)
    const [title, setTitle] = useState('')
    const [notes, setNotes] = useState('')
    const [date, setDate] = useState<Date | null>(null)
    const [startTime, setStartTime] = useState<Date | null>(null)
    const [endTime, setEndTime] = useState<Date | null>(null)
    const [sponsor, setSponsor] = useState<User | undefined>(
        lockEvent != null
            ? SponsorArray(people).find((user) => {
                  return user.name === lockEvent?.sponsor
              })
            : undefined
    )
    const [leader, setLeader] = useState<User | undefined>(
        lockEvent != null
            ? LeaderArray(people).find(
                  (user) => user.name === lockEvent?.leader
              )
            : undefined
    )
    //for value changes
    useEffect(() => {
        if (lockEvent != null) {
            if (isInitalSetup) {
                setDate(lockEvent.end)
                setTitle(lockEvent.title)
                setNotes(lockEvent.notes)
                setEndTime(lockEvent.end)
                setStartTime(lockEvent.start)
                setIsInitialSetup(false)
            }
            setValue('endTime', endTime, { shouldValidate: true })
            setValue('startTime', startTime, { shouldValidate: true })
            setValue('eventName', title, { shouldValidate: true })
            setValue('notes', notes, { shouldValidate: true })
            setValue('eventDate', date, { shouldValidate: true })
            setValue('eventSponsor', sponsor?.name as string, {
                shouldValidate: true,
            })
            setValue('designatedLeader', leader?.name as string, {
                shouldValidate: true,
            })
            trigger()
        }
    }, [
        date,
        startTime,
        endTime,
        title,
        notes,
        leader,
        sponsor,
        setValue,
        trigger,
        lockEvent,
        isInitalSetup,
    ])

    const handleDateOpen = () => {
        setDateOpen(true)
    }
    const handleDateClose = () => {
        setDateOpen(false)
    }

    const handleStartOpen = () => {
        setStartOpen(true)
    }

    const handleStartClose = () => {
        setStartOpen(false)
    }

    const handleEndOpen = () => {
        setEndOpen(true)
    }

    const handleEndClose = () => {
        setEndOpen(false)
    }
    //validation helper functions
    const eventDateValid = async (date: Date | null) => {
        if (date != null) {
            return isBefore(new Date(), date)
        } else {
            return false
        }
    }

    const eventEndTimeValid = (endTime: Date | null) => {
        let startTime = getValues('startTime')

        if (startTime != null && endTime != null) {
            return isAfter(endTime, startTime)
        } else {
            return false
        }
    }
    function OnChangeProcessor(
        onChange: (...event: any[]) => void,
        setProp: Dispatch<SetStateAction<any>>,
        value: string | Date | null
    ) {
        onChange(value)

        if (lockEvent !== null) {
            setProp(value)
        }
    }
    function OnSponsorLeaderChangeProcessor(
        onChange: (...event: any[]) => void,
        setProp: Dispatch<SetStateAction<User | undefined>>,
        value: User | null
    ) {
        onChange(value?.name)
        if (lockEvent !== null) {
            if (value === null) {
                setProp(undefined)
            } else {
                setProp(value)
            }
        }
    }
    if (!modalOpen) {
        return <></>
    }
    return (
        <ModalView
            title={getTitle(modalType)}
            isModalShowing={modalOpen}
            setIsModalShowing={setModalOpen}
        >
            <ThemeProvider theme={Theme}>
                <form className="w-[350px] sm:w-[640px] md:w-[750px]">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="flex flex-col mt-3 sm:mt-1 mb-3 md:mb-6">
                            <Controller
                                name="eventName"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 100,
                                }}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = lockEvent?.title,
                                        ref,
                                        name,
                                    },
                                    fieldState: { error },
                                    formState: { errors, isValid },
                                }) => (
                                    <TextField
                                        data-testid="event-name"
                                        onChange={(e) => {
                                            OnChangeProcessor(
                                                onChange,
                                                setTitle,
                                                e.target.value
                                            )
                                            trigger('eventName')
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        type="text"
                                        inputRef={ref}
                                        label="Event Name"
                                        className="w-full mt-6 md:mr-6  bg-white rounded border-[#09091C] flex"
                                    />
                                )}
                            />
                            {errors.eventName?.type === 'maxLength' &&
                                ErrorMessage(
                                    'Event Name cannot exceed 100 characters!',
                                    'event-name-error'
                                )}
                        </div>
                        <div className="flex flex-col sm:flex-row justify between md:mt-6">
                            <div className="flex flex-col grow sm:mr-3 mb-3 md:mb-6">
                                <Controller
                                    name="eventDate"
                                    control={control}
                                    rules={{
                                        validate: eventDateValid,
                                    }}
                                    render={({
                                        field: { onChange, value, ref, name },
                                        fieldState: { error },
                                        formState: { errors, isValid },
                                    }) => (
                                        <DatePicker
                                            onChange={(e) => {
                                                OnChangeProcessor(
                                                    onChange,
                                                    setDate,
                                                    e
                                                )
                                                trigger('eventDate')
                                            }}
                                            value={value}
                                            inputRef={ref}
                                            label="Event Date"
                                            closeOnSelect
                                            open={isDateOpen}
                                            onOpen={handleDateOpen}
                                            onClose={handleDateClose}
                                            renderInput={(params) => (
                                                <TextField
                                                    onClick={handleDateOpen}
                                                    data-testid="event-date"
                                                    className="basis-1/3 bg-white sm:mr-6 mt-6 md:mt-0 rounded"
                                                    {...params}
                                                />
                                            )}
                                            components={{
                                                OpenPickerIcon: CalendarIcon,
                                            }}
                                        />
                                    )}
                                />
                                {errors.eventDate?.type === 'validate' &&
                                    ErrorMessage(
                                        'Event Date cant be a past date!',
                                        'event-date-error'
                                    )}
                            </div>
                            <div className="flex flex-col grow sm:mr-3 mb-3 md:mb-6">
                                <Controller
                                    name="startTime"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value, ref, name },
                                        fieldState: { error },
                                        formState: { errors, isValid },
                                    }) => (
                                        <TimePicker
                                            onChange={(e) => {
                                                OnChangeProcessor(
                                                    onChange,
                                                    setStartTime,
                                                    e
                                                )
                                                trigger('endTime')
                                            }}
                                            value={value}
                                            inputRef={ref}
                                            label="Start Time"
                                            closeOnSelect
                                            open={isStartOpen}
                                            onOpen={handleStartOpen}
                                            onClose={handleStartClose}
                                            components={{
                                                OpenPickerIcon: DropdownIcon,
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    onClick={handleStartOpen}
                                                    data-testid="event-start"
                                                    className="basis-1/3 bg-white sm:mr-6 mt-3 md:mt-6 rounded"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>
                            <div className="flex flex-col grow">
                                <Controller
                                    name="endTime"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: eventEndTimeValid,
                                    }}
                                    render={({
                                        field: { onChange, value, ref, name },
                                        fieldState: { error },
                                        formState: { errors, isValid },
                                    }) => (
                                        <TimePicker
                                            onChange={(e) => {
                                                OnChangeProcessor(
                                                    onChange,
                                                    setEndTime,
                                                    e
                                                )
                                                trigger('endTime')
                                            }}
                                            value={value}
                                            inputRef={ref}
                                            label="End Time"
                                            closeOnSelect
                                            open={isEndOpen}
                                            onOpen={handleEndOpen}
                                            onClose={handleEndClose}
                                            components={{
                                                OpenPickerIcon: DropdownIcon,
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    onClick={handleEndOpen}
                                                    data-testid="event-end"
                                                    className="basis-1/3 bg-white mt-3 md:mt-6 rounded"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                {errors.endTime?.type === 'validate' &&
                                    ErrorMessage(
                                        'End Time should be after Start Time!',
                                        'event-end-error'
                                    )}
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row">
                            {modalType !== LockEventFormType.EDIT && (
                                <Controller
                                    name="repeatEvent"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            ref,
                                            name,
                                        },
                                        fieldState: { error },
                                        formState: { errors, isValid },
                                    }) => (
                                        <Autocomplete
                                            disablePortal
                                            openOnFocus
                                            onChange={(event, value) => {
                                                setValue(
                                                    'repeatEvent',
                                                    value?.label as string
                                                )
                                                trigger('repeatEvent')
                                            }}
                                            onBlur={onBlur}
                                            ref={ref}
                                            popupIcon={<DropdownIcon />}
                                            placeholder="Recurrence"
                                            options={recurrenceList}
                                            className="basis-1/2 sm:mr-3 bg-white mt-3 sm:mt-0 md:mt-0 rounded"
                                            renderInput={(params) => (
                                                <TextField
                                                    data-testid="recurrence"
                                                    {...params}
                                                    label="Recurrence"
                                                />
                                            )}
                                        />
                                    )}
                                />
                            )}
                            <Controller
                                name="eventSponsor"
                                control={control}
                                rules={{ required: true }}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                        name,
                                    },
                                    fieldState: { error },
                                    formState: { errors, isValid },
                                }) => (
                                    <Autocomplete
                                        disablePortal
                                        openOnFocus
                                        onChange={(event, value) => {
                                            OnSponsorLeaderChangeProcessor(
                                                onChange,
                                                setSponsor,
                                                value
                                            )
                                            trigger('eventSponsor')
                                        }}
                                        onBlur={onBlur}
                                        ref={ref}
                                        forcePopupIcon={true}
                                        popupIcon={<SearchIcon />}
                                        value={sponsor}
                                        placeholder="Event Sponsor"
                                        options={SponsorArray(people)}
                                        sx={{
                                            [`& .${autocompleteClasses.popupIndicator}`]:
                                                {
                                                    transform: 'none',
                                                },
                                        }}
                                        isOptionEqualToValue={isPersonEqual}
                                        getOptionLabel={(user) => user.name}
                                        className="basis-1/2 sm:mr-3 bg-white mt-3 sm:mt-0 md:mt-0 rounded"
                                        renderInput={(params) => (
                                            <TextField
                                                data-testid="event-sponsor"
                                                {...params}
                                                label="Event Sponsor"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="designatedLeader"
                                control={control}
                                rules={{ required: true }}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                        name,
                                    },
                                    fieldState: { error },
                                    formState: { errors, isValid },
                                }) => (
                                    <Autocomplete
                                        disablePortal
                                        openOnFocus
                                        onChange={(event, value) => {
                                            OnSponsorLeaderChangeProcessor(
                                                onChange,
                                                setLeader,
                                                value
                                            )
                                            trigger('designatedLeader')
                                        }}
                                        onBlur={onBlur}
                                        ref={ref}
                                        popupIcon={<SearchIcon />}
                                        placeholder="Designated Leader"
                                        options={LeaderArray(people)}
                                        sx={{
                                            [`& .${autocompleteClasses.popupIndicator}`]:
                                                {
                                                    transform: 'none',
                                                },
                                        }}
                                        isOptionEqualToValue={isPersonEqual}
                                        getOptionLabel={(user) => user.name}
                                        value={leader}
                                        className="basis-1/2 bg-white mt-3 sm:mt-0 md:mt-0 rounded"
                                        renderInput={(params) => (
                                            <TextField
                                                data-testid="event-leader"
                                                {...params}
                                                label="Designated Leader"
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>

                        <div className="flex flex-col my-3 md:my-6">
                            <Controller
                                name="notes"
                                control={control}
                                rules={{ maxLength: 300 }}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        ref,
                                        name,
                                    },
                                    fieldState: { error },
                                    formState: { errors, isValid },
                                }) => (
                                    <TextField
                                        onChange={(e) => {
                                            OnChangeProcessor(
                                                onChange,
                                                setNotes,
                                                e.target.value
                                            )
                                            trigger('notes')
                                        }}
                                        onBlur={onBlur}
                                        inputRef={ref}
                                        value={value}
                                        type="text"
                                        label="Notes"
                                        className="bg-white w-full mt-3 md:mt-6 rounded"
                                        multiline={true}
                                        rows={3}
                                        inputProps={{
                                            'data-testid': 'event-notes',
                                        }}
                                    />
                                )}
                            />
                            {errors.notes?.type === 'maxLength' &&
                                ErrorMessage(
                                    'Notes cannot exceed 300 characters!',
                                    'event-notes-error'
                                )}
                        </div>
                        <div className="flex flex-row-reverse gap-3">
                            <Button
                                text={getSubmitButtonText(modalType)}
                                onClick={handleSubmit(onSubmit)}
                                submitting={isSubmitted}
                                disabled={!isValid}
                                testid="add-new-lock-event-submit"
                            />
                            <Button
                                text="Cancel"
                                onClick={onCancel}
                                type={ButtonType.Secondary}
                                testid="add-new-lock-event-cancel"
                            />
                        </div>
                    </LocalizationProvider>
                </form>
            </ThemeProvider>
        </ModalView>
    )
}
