import { Autocomplete, TextField, ThemeProvider } from '@mui/material'
import { API } from 'aws-amplify'
import { FunctionComponent, useContext } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Theme } from '../../components/AddOrModifyLockEvent/CustomTheme'
import { ErrorMessage } from '../../components/AddOrModifyLockEvent/ErrorMessage'
import { DropdownIcon } from '../../components/AddOrModifyLockEvent/SvgIcons'
import { Button, ButtonType } from '../../components/Button'
import { ToastDef, ToastType } from '../../components/Toast'
import { APIName } from '../../store/auth'
import { LocationContext, LocationList } from '../../store/locations'
import { getUsers, User, UserActionType, UserContext } from '../../store/users'

export enum UserFormType {
    Add,
    Edit,
}

export type UserFormProps = {
    type: UserFormType
    setIsModalOpen: (state: boolean) => void
    setToast: (def: ToastDef) => void
    user?: User
}

export const UserForm: FunctionComponent<UserFormProps> = ({
    type,
    setIsModalOpen,
    setToast,
    user: initialUser,
}) => {
    const [locations] = useContext(LocationContext)
    const [users, userDispatch] = useContext(UserContext)

    const defaultFormValues: User = initialUser ?? {
        name: '',
        email: '',
        title: '',
        locationId: '',
        isLeader: false,
        isSponsor: false,
    }

    const {
        control,
        formState: { isValid, isSubmitted },
        handleSubmit,
        reset,
        getValues,
        setValue,
        trigger,
        watch,
    } = useForm<User>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: defaultFormValues,
        shouldFocusError: true,
    })

    const onSubmit: SubmitHandler<User> = async  (user: User) => {
        if (initialUser && (initialUser.email !== user.email || initialUser.locationId !== user.locationId)) {
            await API.del(APIName, '/user', {
                body: {
                    email: initialUser.email,
                    locationId: initialUser.locationId,}
            })
            userDispatch({ type: UserActionType.Delete, UserEmail: initialUser.email })
        }

        API.put(APIName, '/user', {
            body: user,
        })
            .then((e: { result: User }) => {
                setToast({
                    type: ToastType.Success,
                    contents: (
                        <div className="font-bold">
                            {type === UserFormType.Add
                                ? 'User successfully added'
                                : 'User successfully updated'}
                        </div>
                    ),
                })
                userDispatch({ type: UserActionType.Put, User: e.result })
            })
            .catch((e: User) => {
                setToast({
                    type: ToastType.Error,
                    contents: (
                        <>
                            <div className="font-bold">
                                User add/edit Failed
                            </div>
                            <div>Please see the console for more details.</div>
                        </>
                    ),
                })
            })
            .finally(() => {
                setIsModalOpen(false)
                getUsers(users, userDispatch, true)
            })
    }

    return (
        <ThemeProvider theme={Theme}>
            <form className="flex flex-col" autoComplete="off">
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Name is required',
                        },
                        maxLength: {
                            value: 100,
                            message: 'Name must be less than 100 characters',
                        },
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <>
                            <TextField
                                data-testid="user-name"
                                onChange={(e) => {
                                    onChange(e)
                                    trigger('name')
                                }}
                                sx={{ marginTop: '1.5rem' }}
                                onBlur={onBlur}
                                value={value}
                                type="text"
                                inputRef={ref}
                                label="Name"
                                className="w-full bg-white rounded border-[#09091C]"
                            />
                            {error &&
                                ErrorMessage(
                                    error.message ?? 'Error',
                                    'user-name-error'
                                )}
                        </>
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Email is required',
                        },
                        maxLength: {
                            value: 100,
                            message: 'Email must be less than 100 characters',
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        },
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <>
                            <TextField
                                data-testid="user-email"
                                onChange={(e) => {
                                    onChange(e)
                                    trigger('email')
                                }}
                                onBlur={onBlur}
                                value={value}
                                type="text"
                                inputRef={ref}
                                label="Email"
                                sx={{ marginTop: '1.5rem' }}
                                className="w-full bg-white rounded border-[#09091C]"
                            />
                            {error &&
                                ErrorMessage(
                                    error.message ?? 'Error',
                                    'user-email-error'
                                )}
                        </>
                    )}
                />
                <Controller
                    name="title"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Title is required',
                        },
                        maxLength: {
                            value: 100,
                            message: 'Title must be less than 100 characters',
                        },
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <>
                            <TextField
                                data-testid="user-title"
                                onChange={(e) => {
                                    onChange(e)
                                    trigger('title')
                                }}
                                onBlur={onBlur}
                                value={value}
                                type="text"
                                inputRef={ref}
                                label="Title"
                                sx={{ marginTop: '1.5rem' }}
                                className="w-full bg-white rounded border-[#09091C]"
                            />
                            {error &&
                                ErrorMessage(
                                    error.message ?? 'Error',
                                    'user-title-error'
                                )}
                        </>
                    )}
                />
                <Controller
                    name="locationId"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Location is required',
                        },
                    }}
                    render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                    }) => (
                        <>
                            <Autocomplete
                                disablePortal
                                openOnFocus
                                onChange={(event, value) => {
                                    if (value) {
                                        onChange(event)
                                        setValue('locationId', value.locationId)
                                        trigger('locationId')
                                    }
                                }}
                                onBlur={onBlur}
                                ref={ref}
                                popupIcon={<DropdownIcon />}
                                value={
                                    locations.get(getValues('locationId')) ??
                                    null
                                }
                                options={LocationList(locations)}
                                getOptionLabel={(loc) => loc.name}
                                className="w-full bg-white my-6 rounded"
                                renderInput={(params) => (
                                    <TextField
                                        data-testid="location"
                                        {...params}
                                        label="Location"
                                    />
                                )}
                            />
                            {error &&
                                ErrorMessage(
                                    error.message ?? 'Error',
                                    'user-location-error'
                                )}
                        </>
                    )}
                />
                <h4 className="text-xl mb-3">Permissions</h4>
                <div className="flex flex-row flex-wrap gap-2 mb-3">
                    <button
                        className={
                            'rounded-xl h-9 border-2 border-[#3E3E67] px-4 transition-colors whitespace-nowrap ' +
                            (watch('isSponsor')
                                ? 'bg-[#3E3E67] hover:bg-[#3E3E67]/75'
                                : 'hover:bg-[#3E3E67]/30')
                        }
                        onClick={() => {
                            setValue('isSponsor', !watch('isSponsor'))
                            trigger('isSponsor')
                        }}
                        type="button"
                    >
                        Event Sponsor
                    </button>
                    <button
                        className={
                            'rounded-xl h-9 border-2 border-[#3E3E67] px-4 transition-colors whitespace-nowrap ' +
                            (watch('isLeader')
                                ? 'bg-[#3E3E67] hover:bg-[#3E3E67]/75'
                                : 'hover:bg-[#3E3E67]/30')
                        }
                        onClick={() => {
                            setValue('isLeader', !watch('isLeader'))
                            trigger('isLeader')
                        }}
                        type="button"
                    >
                        Designated Leader
                    </button>
                </div>
                <div className="flex flex-row my-3 justify-end gap-3">
                    <Button 
                        text="Cancel"
                        type={ButtonType.Secondary}
                        onClick={() => {
                            reset()
                            setIsModalOpen(false)
                        }}
                        disabled={isSubmitted}
                    />
                    <Button 
                        text={type === UserFormType.Add ? 'Add New User' : 'Save'}
                        testid='submit-button'
                        disabled={!isValid}
                        submitting={isSubmitted}
                        onClick={handleSubmit(onSubmit)}
                    />
                </div>
            </form>
        </ThemeProvider>
    )
}
