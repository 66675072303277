import { FunctionComponent, useState } from 'react'
import mark from '../../assets/svg/SlalomBuild.svg'
import logo from '../../assets/png/SlalomLogoWhiteCyan.png'
import chicagoimage from '../../assets/svg/ChicagoBCIcon.svg'
import dropdown from '../../assets/svg/DropdownMark.svg'
import './nav.css'
import { LogOutItem, NavItem } from './NavItem'
import { NavLink } from 'react-router-dom'
import { IconType, renderIcon } from './Icon'

type Props = {
    children: JSX.Element
    locationShowing: boolean
}

export const Nav: FunctionComponent<Props> = (props) => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    return (
        <div className="overflow-x-hidden bg-[#070717] text-white">
            <div
                className={
                    'transition-opacity w-full min-h-screen fixed top-0 left-0 right-0 bg-black z-40 ' +
                    (navbarOpen
                        ? 'translate-x-0 opacity-75'
                        : '-translate-x-full opacity-0 pointer-events-none')
                }
                onClick={() => {
                    setNavbarOpen(false)
                }}
            >
                {/* dummy div for darkening window contents during the offcanvas open */}
            </div>
            <div
                className={
                    'transition-transform w-full min-h-screen ' +
                    (navbarOpen ? 'translate-x-72 ' : 'translate-x-0')
                }
            >
                <header className="h-16 lg:h-24 bg-black flex justify-between content-center items-center border-b border-[#20203B]">
                    <div className="hidden lg:flex text-white justify-between content-center items-center">
                        <img
                            src={mark}
                            className="h-7 ml-8 mr-4 xl:mr-8"
                            alt="Slalom Build Mark"
                        ></img>
                        <NavLink
                            to="/dashboard/"
                            className="mr-4 xl:mr-12 pb-1"
                            id="bca_title"
                        >
                            <span className="text-cyan mr-1 text-4xl font-normal tracking-wide">leia</span>
                        </NavLink>
                        <NavItem
                            text={'Dashboard'}
                            icon={IconType.Dashboard}
                            mobile={false}
                            to={'/dashboard/'}
                        ></NavItem>
                        <NavItem
                            text={'Lock Event Management'}
                            icon={IconType.Lock}
                            mobile={false}
                            to={'/lock-event-management/'}
                        ></NavItem>
                        <NavItem
                            text={'Admin'}
                            icon={IconType.Admin}
                            mobile={false}
                            to={'/admin/'}
                        ></NavItem>
                    </div>
                    <div className="hidden lg:flex text-white justify-between content-center items-center h-full">
                        {props.locationShowing && (
                            <div
                                className=" h-full py-5 px-1 xl:px-2 flex items-center"
                                id="desktopBCSelect"
                            >
                                <img
                                    src={chicagoimage}
                                    className="mr-2 xl:mr-3"
                                    alt="Chicago Build Center Logo"
                                ></img>
                                Chicago BC
                                <img
                                    src={dropdown}
                                    className="inline m-1 ml-2 pt-1"
                                    alt="Open Selector Dropdown"
                                ></img>
                            </div>
                        )}

                        <LogOutItem
                            text={'Sign Out'}
                            icon={IconType.Dashboard}
                            mobile={false}
                        ></LogOutItem>
                    </div>
                    <div className="flex lg:hidden text-grey justify-start content-center items-center w-16">
                        <button
                            className="bg-[#20203B] rounded p-2 mx-6"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            data-testid="navbar-hamburger"
                        >
                            {renderIcon(IconType.Hamburger, ' fill-white')}
                        </button>
                    </div>

                    <div className="lg:hidden text-white text-2xl font-extralight pb-2 pt-1">
                        <span className="text-cyan mr-1 text-3xl font-normal tracking-wide">leia</span>
                    </div>
                    <div className="block lg:hidden w-16">
                        {/* Dummy div to center logo above that has same width of hamburger button*/}
                    </div>
                </header>
                {props.children}
            </div>
            <div
                className={
                    'transition-transform w-72 block offcanvas-gradient fixed h-full top-0 left-0 z-50 text-white ' +
                    (navbarOpen ? 'translate-x-0' : '-translate-x-72')
                }
                data-testid="mobile-menu"
            >
                <img
                    src={logo}
                    className="h-6 w-auto ml-6 my-7 "
                    alt="Slalom Build Logo"
                ></img>
                <button className="flex h-12 mb-4 w-full border-y text-grey border-grey/25 justify-end content-center items-center">
                    <img
                        src={chicagoimage}
                        alt="Chicago Build Center"
                        className="w-5 ml-7"
                    ></img>
                    <div className="ml-6 mr-auto">Chicago BC</div>
                    <img
                        src={dropdown}
                        alt="Dropdown Open"
                        className="inline h-2 mr-4 justify-self-end"
                    ></img>
                </button>
                <div onClick={() => setNavbarOpen(!navbarOpen)}>
                    <NavItem
                        text={'Dashboard'}
                        icon={IconType.Dashboard}
                        mobile={true}
                        to={'/dashboard/'}
                    ></NavItem>
                    <NavItem
                        text={'Lock Event Management'}
                        icon={IconType.Lock}
                        mobile={true}
                        to={'/lock-event-management/'}
                    ></NavItem>
                    <NavItem
                        text={'Admin'}
                        icon={IconType.Admin}
                        mobile={true}
                        to={'/admin/'}
                    ></NavItem>
                    <LogOutItem
                        text={'Sign Out'}
                        icon={IconType.LogOut}
                        mobile={true}
                    ></LogOutItem>
                </div>
            </div>
        </div>
    )
}
