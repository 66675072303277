import { FunctionComponent } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import close_icon from '../assets/svg/CloseMark.svg'
import Styles from './ModalView.module.css'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  height: 'fit-content',
  bgcolor: '#12122D',
  fontFamily: 'Slalom-Sans',
  boxSizing: 'border-box',
  color: 'white',
  p: 3,
};
interface IProps {
  isModalShowing:boolean;
  title:string;
  children: JSX.Element;
  setIsModalShowing: (isShowing: boolean) => void; 
}
export const ModalView: FunctionComponent<IProps> = ({setIsModalShowing,isModalShowing,title,children}) =>{

  const handleClose = () => setIsModalShowing(false);

  return (
    <div>
      <Modal
        open={isModalShowing}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <div className={Styles["modal-header"]}>
              <h2 className={Styles["modal-title"]}>{title}</h2>
              <button onClick={handleClose} className={Styles["modal-close-button"]}>
                  <img src={close_icon} alt="Close" className={Styles["modal-close-icon"]}></img>
              </button>
            </div>
            <div className={Styles["modal-body"]}>
                {children}
            </div>
        </Box>
      </Modal>
    </div>
  );

}