import {
    FunctionComponent,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { DashboardIcon } from './LockIcon'
import close_icon from '../../assets/svg/CloseMark.svg'
import styles from './Locklist.module.css'
import arrow from '../../assets/svg/arrow.svg'
import Draggable, { DraggableData } from 'react-draggable'
import {
    fetchLocks,
    ILock,
    lockActionType,
    LockContext,
    LockDisplayState,
} from '../../store/locks'
import {
    EventContext,
    getEventsInProgressTodayByLock,
} from '../../store/events'
import {
    format,
    formatDistanceToNow,
    isWithinInterval,
    subMinutes,
} from 'date-fns'

import { IoTSubSetup, SetupIot } from '../../store/auth'
import { LockStatusIcon } from './LockStatusIcon'

function useForceUpdate() {
    const [, setValue] = useState(0)

    // update state to force render
    return () => setValue((value) => value + 1)
}

export type LockListProps = {
    selectedLockId?: string
    setSelectedLockId: (lockID: string | undefined) => void
}

export const Locklist: FunctionComponent<LockListProps> = ({
    selectedLockId,
    setSelectedLockId,
}) => {
    const forceUpdate = useForceUpdate()
    const [selectedLock, setSelectedLock] = useState<ILock | null>(null)
    const [eventState] = useContext(EventContext)
    const [locks, lockDispatch] = useContext(LockContext)
    const sliderMax = 216 // in px, how long the slider is
    const sliderMin = 0
    const sliderBuffer = 165 // how far from start in px for the slider to be considered "active"
    const [isIotSetupComplete, setIsIotSetupComplete] = useState(false)
    const [sliderVal, setSliderVal] = useState(0)
    const [updatedLockID, setUpdatedLockID] = useState<string | null>(null)
    const [sliderCurrentlyInteracting, setSliderInteraction] = useState(false)
    const [sliderActive, setSliderActive] = useState(false)
    const sliderDOMRef = useRef(null) // required for react draggable

    const [sliderDisabled, setSliderDisabled] = useState(false)
    useEffect(() => {
        setSliderDisabled(
            selectedLock?.displayState === LockDisplayState.Locking ||
                selectedLock?.displayState === LockDisplayState.Unlocking
        )
    }, [selectedLock])

    useEffect(() => {
        if (selectedLockId) {
            const lock = locks.get(selectedLockId)
            if (lock) {
                setSelectedLock(lock)
                setSliderActive(lock.unlocked())
            }
            setSelectedLockId(undefined)
        }
    }, [selectedLockId, locks, setSelectedLockId])

    useEffect(() => {
        if (!isIotSetupComplete) {
            fetchLocks(lockDispatch).catch((error) => {
                new Error(error)
            })
            SetupIot()
                .then()
                .catch((error) => {
                    new Error(error)
                })
            setIsIotSetupComplete(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const lockEvents = useMemo(() => {
        if (selectedLock) {
            return getEventsInProgressTodayByLock(
                eventState,
                selectedLock.deviceId
            )
        } else {
            return []
        }
    }, [selectedLock, eventState])

    useEffect(() => {
        if (
            updatedLockID &&
            selectedLock &&
            selectedLock.deviceId === updatedLockID
        ) {
            setSliderActive(selectedLock.unlocked())
            setUpdatedLockID(null)
            setSliderDisabled(
                selectedLock?.displayState === LockDisplayState.Locking ||
                    selectedLock?.displayState === LockDisplayState.Unlocking
            )
        }
    }, [selectedLock, updatedLockID])

    useEffect(() => {
        IoTSubSetup(locks, lockDispatch, forceUpdate, setUpdatedLockID)
        if (selectedLock) {
            const new_lock = locks.get(selectedLock.deviceId)
            if (new_lock) {
                setSelectedLock(new_lock)
                setSliderDisabled(
                    selectedLock?.displayState === LockDisplayState.Locking ||
                        selectedLock?.displayState ===
                            LockDisplayState.Unlocking
                )
            }
        }
        return () => {
            locks.forEach((lock: ILock) => {
                lock.sub?.unsubscribe()
            })
        }
    }, [locks, selectedLock, forceUpdate, lockDispatch])

    useEffect(() => {
        if (!sliderCurrentlyInteracting) {
            setSliderVal(sliderActive ? sliderMax : sliderMin)
        }
    }, [sliderActive, sliderCurrentlyInteracting])

    const updateVal = (_: any, data: DraggableData) => {
        if (sliderCurrentlyInteracting) {
            setSliderVal(data.x)
            if (!sliderActive && sliderVal > sliderBuffer) {
                setSliderActive(true)
                setSliderDisabled(true)
                if (selectedLock) {
                    lockDispatch({
                        type: lockActionType.UserUnlockCommand,
                        lockId: selectedLock?.deviceId,
                    })
                }
            } else if (sliderActive && sliderVal < sliderBuffer) {
                setSliderActive(false)
                setSliderDisabled(true)
                if (selectedLock) {
                    lockDispatch({
                        type: lockActionType.UserLockCommand,
                        lockId: selectedLock?.deviceId,
                    })
                }
            }
        }
    }
    var locks_rendered: Array<ReactElement> = []
    locks.forEach((lock) => {
        let lock_color = 'text-white'
        let text = 'UNLOCKED'
        switch (lock.displayState) {
            case LockDisplayState.Offline:
                lock_color = 'text-white'
                text = 'OFFLINE'
                break
            case LockDisplayState.Unlocked:
                lock_color = 'text-[#2184CC]'
                text = 'UNLOCKED'
                break
            case LockDisplayState.Locked:
                lock_color = 'text-white'
                text = 'LOCKED'
                break
            case LockDisplayState.Locking:
                text = 'LOCKING'
                break
            case LockDisplayState.Unlocking:
                text = 'UNLOCKING'
                break
        }

        locks_rendered.push(
            <button
                key={lock.deviceId}
                onClick={() => {
                    setSelectedLock(lock)
                    setSliderActive(lock.unlocked())
                }}
                className={
                    'w-40 h-36 mr-4  transition-colors rounded-xl border  flex flex-col shrink-0 ' +
                    (lock === selectedLock
                        ? 'bg-[#272746] border-[#1BE1F2]'
                        : 'hover:bg-[#272746] bg-[#13132E] border-[#20203B]')
                }
                data-testid={`lock-button-id ${lock.deviceId}`}
            >
                <div className="grow place-items-center flex place-content-center text-sm w-full">
                    <div className="mx-auto">{lock.name}</div>
                </div>
                <div className="place-content-center flex h-16 w-full">
                    <DashboardIcon state={lock.displayState}></DashboardIcon>
                </div>
                <div
                    className={
                        'grow tracking-[5px] font-bold place-items-center flex place-content-center text-xs w-full ' +
                        lock_color
                    }
                >
                    <span>{text}</span>
                </div>
            </button>
        )
    })

    return (
        <>
            <div className="shrink-0 flex overflow-x-auto mb-4">
                {locks_rendered}
            </div>
            <div
                className={
                    'absolute z-50 top-16 lg:top-24 right-0 h-[calc(100vh-4rem)] lg:h-[calc(100vh-6rem)] max-w-full w-96 offcanvas-gradient transition-transform flex flex-col items-center p-8 ' +
                    (!selectedLock && 'translate-x-96')
                }
                data-testid={'unlock/lock dialog'}
                key={selectedLock?.deviceId}
            >
                <div
                    className="flex justify-between text-white w-full"
                    data-testid={'lock-dialog-close-button'}
                >
                    <h2 className="text-2xl font-bold">{selectedLock?.name}</h2>
                    <button
                        onClick={() => {
                            setSelectedLock(null)
                        }}
                        className="ml-auto transition-colors hover:bg-[#20203B] hover:border-[#20203B]/50 rounded-sm px-2 py-1"
                    >
                        <img src={close_icon} alt="Close"></img>
                    </button>
                </div>
                {selectedLock ? <LockStatusIcon lock={selectedLock} /> : <></>}
                {lockEvents.length !== 0 && (
                    <div className="w-full text-sm">
                        <h3 className="text-cyan tracking-[2.5px] uppercase" data-testid="in-progress-list-title">
                            In Progress Events
                        </h3>
                        {lockEvents.map((event) => {
                            return (
                                <div key={event.id} data-testid={`in-progress-event-${event.id}`}>
                                    <div className="font-bold mt-2">
                                        {event.title}
                                    </div>
                                    <div>
                                        ({format(event.start, 'h:mmaaa')} -{' '}
                                        {format(event.end, 'h:mmaaa')})
                                    </div>
                                    {isWithinInterval(new Date(), {
                                        start: subMinutes(event.end, 30),
                                        end: event.end,
                                    }) ? (
                                        <div className="text-[#898989]">
                                            {formatDistanceToNow(event.end)}{' '}
                                            remaining
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
                {selectedLock?.displayState !== LockDisplayState.Offline ? (
                    <div className="mt-auto relative block">
                        <div
                            className={` ${styles['slider-unlock']}
                                ${
                                    sliderActive
                                        ? styles['active']
                                        : styles['inactive']
                                }
                                ${
                                    selectedLock?.displayState ===
                                        LockDisplayState.Locked &&
                                    styles['display-text']
                                }
                                ${sliderDisabled ? styles['no-interact'] : ''}`}
                        >
                            <Draggable
                                axis="x"
                                defaultPosition={{ x: 0, y: 0 }}
                                bounds={{
                                    left: 0,
                                    top: 0,
                                    right: sliderMax,
                                    bottom: 0,
                                }}
                                position={{ x: sliderVal, y: 0 }}
                                onStart={() => {
                                    setSliderInteraction(true)
                                }}
                                onStop={() => {
                                    setSliderInteraction(false)
                                }}
                                onDrag={updateVal}
                                nodeRef={sliderDOMRef}
                                disabled={sliderDisabled}
                            >
                                <div
                                    className={`${styles['slider-thumb']} 
                                        ${
                                            !sliderCurrentlyInteracting
                                                ? styles['free']
                                                : ''
                                        }
                                         `}
                                    ref={sliderDOMRef}
                                ></div>
                            </Draggable>
                        </div>
                        <div
                            className={
                                'transition-transform z-[6] relative block w-fit mx-auto h-0 ' +
                                (selectedLock?.displayState ===
                                LockDisplayState.Unlocked
                                    ? ''
                                    : '-translate-y-6')
                            }
                        >
                            <img
                                src={arrow}
                                alt="Left Arrow"
                                className="rotate-180 inline mr-2"
                            />
                            slide back to lock
                        </div>
                    </div>
                ) : (
                    <div className="mt-auto text-error flex flex-col items-center ">
                        <div>
                            Lock on {selectedLock.name} is currently offline.
                        </div>
                        <div className="font-bold">
                            Please check connection.
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
