import { useContext, useEffect, useState } from 'react'
import {
    AddEvent,
    LockEventFormType,
} from '../components/AddOrModifyLockEvent/AddOrModifyLockEventModal'
import { LockEvents } from '../components/LockEventManagement/LockEvents'
import Styles from './LockEventManagement.module.css'

import {
    EventContext,
    EventFetchState,
    fetchEvents,
    LockEvent,
    getEventsToday,
    getFutureEvents,
    getPastEvents,
} from '../store/events'
import { Toast, ToastDef } from '../components/Toast'
import { Button } from '../components/Button'

const LockEventManagement = () => {
    const [eventState, eventDispatch] = useContext(EventContext)
    const [todayEvents, setTodayEvents] = useState<null | LockEvent[]>(null)
    const [futureEvents, setFutureEvents] = useState<null | LockEvent[]>(null)
    const [pastEvents, setPastEvents] = useState<null | LockEvent[]>(null)
    const enum buttonTypes {
        UPCOMING_EVENTS,
        PAST_EVENTS,
    }
    useEffect(() => {
        if (eventState.state !== EventFetchState.Loaded) {
            fetchEvents(eventDispatch)
        } else if (eventState.state === EventFetchState.Loaded) {
            setTodayEvents(getEventsToday(eventState))
            setFutureEvents(getFutureEvents(eventState))
            setPastEvents(getPastEvents(eventState))
        }
    }, [eventDispatch, eventState])

    // const [searchText, setSearchText] = useState('')
    const [toast, setToast] = useState<ToastDef | null>(null)
    const [showModal, setShowModal] = useState(false)
    const [buttonState, setButtonState] = useState(buttonTypes.UPCOMING_EVENTS)
    const checkActiveState = (clickEvent: any) => {
        if (clickEvent.target.innerHTML === 'Upcoming Lock Events') {
            setButtonState(buttonTypes.UPCOMING_EVENTS)
        } else {
            setButtonState(buttonTypes.PAST_EVENTS)
        }
    }
    return (
        <>
            {toast && <Toast def={toast} setToast={setToast} />}
            <div className={Styles['header']}>
                <h1 className={Styles['event-page-title']}>
                    Lock Event Management
                </h1>
                <div className="ml-auto mt-[.5rem]">
                    <Button
                        text="Add New Event"
                        plus_icon={true}
                        onClick={() => {
                            setShowModal(true)
                        }}
                    />
                </div>
            </div>
            <div className={Styles['button-container']}>
                <button
                    className={Styles['upcoming-lock-event-button']}
                    onClick={(clickEvent) => {
                        checkActiveState(clickEvent)
                    }}
                >
                    Upcoming Lock Events
                </button>
                <button
                    className={Styles['past-lock-event-button']}
                    onClick={(clickEvent) => {
                        checkActiveState(clickEvent)
                    }}
                >
                    Past Lock Events
                </button>
                <div
                    className={`${Styles['active-highlight']} ${
                        buttonState === buttonTypes.PAST_EVENTS &&
                        Styles['over']
                    }`}
                ></div>
            </div>

            {/* TODO : Implement search functionality in BCAA-137 */}
            {/* 
            <div className="h-fit flex gap-2 lg:px-24 px-6 pb-6">
                <div className="border border-[#2B2B53] rounded-sm shrink flex items-stretch min-w-0 focus:">
                    <input
                        type="text"
                        className="appearance-none bg-black/0 p-1 pl-2 shrink basis-64 min-w-0 w-64"
                        placeholder="Search"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                    />
                    <button className="bg-[#2B2B53] shrink-0 ">
                        <img className="mx-1" src={SearchIcon} alt="Search" />
                    </button>
                </div>
            </div> */}

            {buttonState === buttonTypes.UPCOMING_EVENTS &&
            todayEvents?.length ? (
                <LockEvents
                    events={todayEvents as LockEvent[]}
                    getToast={setToast}
                    listTitle={"Today's Events"}
                    isDashboard={false}
                ></LockEvents>
            ) : (
                // TODO: update this to a better spinner (once backend impl is integrated)
                <div></div>
            )}
            {buttonState === buttonTypes.UPCOMING_EVENTS &&
            futureEvents?.length ? (
                <LockEvents
                    events={futureEvents as LockEvent[]}
                    getToast={setToast}
                    listTitle={'Future Events'}
                    isDashboard={false}
                ></LockEvents>
            ) : (
                // TODO: update this to a better spinner (once backend impl is integrated)
                <div></div>
            )}
            {buttonState === buttonTypes.PAST_EVENTS && pastEvents?.length ? (
                <LockEvents
                    events={pastEvents as LockEvent[]}
                    getToast={setToast}
                    listTitle={''}
                    isDashboard={false}
                ></LockEvents>
            ) : (
                // TODO: update this to a better spinner (once backend impl is integrated)
                <div></div>
            )}
            {showModal && (
                <AddEvent
                    modalOpen={showModal}
                    setModalOpen={setShowModal}
                    modalType={LockEventFormType.ADD}
                    getToast={setToast}
                />
            )}
        </>
    )
}
export default LockEventManagement
