import { compareAsc, compareDesc, isAfter, isBefore, isToday } from 'date-fns'
import { API } from 'aws-amplify'
import { createContext, Dispatch } from 'react'
import { APIName } from './auth'

async function getEvents() {
    const getEventsPath = '/events'
    const response = await API.get(APIName, getEventsPath, {})
    return response.result
}

export enum EventActionType {
    Reload,
    LoadEvents,
    AddNewEvents,
    DeleteEvent
}

export enum EventFetchState {
    Loading,
    Loaded,
    Failed
}

export const EventContext = createContext<
    [EventState, React.Dispatch<EventAction>]
>([{ state: EventFetchState.Loading }, () => { }])

export type EventAction =
    | { type: EventActionType.Reload }
    | { type: EventActionType.LoadEvents; events: LockEvent[] }
    | { type: EventActionType.AddNewEvents; newEvents: LockEvent[] }
    | { type: EventActionType.DeleteEvent; event: DeleteEvent }

export type DeleteEvent = {
    eventId: string,
    locationId: string
}

export type LockEvent = {
    id: string
    title: string
    start: Date
    end: Date
    sponsor: string
    leader: string
    notes: string
    locks: string[]
}
export type UserCredentials = {
    name: string
    email: string
}
export interface EventRequestData {
    timesRepeated: number | null
    eventId: string | null
    locationId: string
    deviceIds: string[]
    leader: UserCredentials
    sponsor: UserCredentials
    creator: UserCredentials
    name: string
    notes: string | null
    startTime: string
    endTime: string
}

export type EventState =
    | { state: EventFetchState.Loading }
    | { state: EventFetchState.Loaded; events: Map<string, LockEvent> }
    | { state: EventFetchState.Failed; error: string }

export function eventReducer(
    state: EventState,
    action: EventAction
): EventState {
    switch (action.type) {
        case EventActionType.Reload:
            return state
        case EventActionType.LoadEvents:
            let events: Map<string, LockEvent> = new Map()
            if (state.state === EventFetchState.Loaded) {
                state.events.forEach((event) => {
                    events.set(event.id, event)
                })
            }
            action.events.forEach((event) => {
                events.set(event.id, event)
            })
            return { state: EventFetchState.Loaded, events }
        case EventActionType.AddNewEvents:
            let newEventList: Map<string, LockEvent> = new Map()
            if (state.state === EventFetchState.Loaded) {
                newEventList = state.events
                for (const event of action.newEvents) {
                    newEventList.set(event.id, event)
                }
            }
            return { state: EventFetchState.Loaded, events: newEventList }
        case EventActionType.DeleteEvent:
            let eventsList: Map<string, LockEvent> = new Map()
            if (state.state === EventFetchState.Loaded) {
                eventsList = state.events
                eventsList.delete(action.event.eventId)
            }
            return { state: EventFetchState.Loaded, events: eventsList }
        default:
            return state
    }
}


export async function fetchEvents(dispatch: Dispatch<EventAction>) {
    const result = await getEvents()
    const events: LockEvent[] = convertResponseToLockEventList(result)

    dispatch({ type: EventActionType.LoadEvents, events: events })
}

export function convertResponseToLockEventList(responseData: []) {
    let eventList: LockEvent[] = []

    responseData.forEach(
        (element: {
            deviceIds: string[]
            eventId: string
            name: string
            startTime: Date
            endTime: Date
            sponsor: { name: string }
            leader: { name: string }
            notes: string
        }) => {
            eventList.push({
                id: element.eventId,
                title: element.name,
                start: new Date(element.startTime),
                end: new Date(element.endTime),
                sponsor: element.sponsor.name,
                leader: element.leader.name,
                notes: element.notes,
                locks: element.deviceIds,
            })
        }
    )

    return eventList
}
export function getFilteredEvents(
    state: EventState,
    condition: (e: LockEvent) => boolean
) {
    let events: LockEvent[] = []
    if (state.state === EventFetchState.Loaded) {
        events = Array.from(state.events.values())
    } else {
        throw new Error('Tried to filter events while events not loaded')
    }
    var ret: LockEvent[] = []
    events.forEach((event) => {
        if (condition(event)) {
            ret.push(event)
        }
    })
    return ret
}

export function getEventsToday(state: EventState) {
    return getFilteredEvents(state, (e) => {
        return isToday(e.start)
    }).sort((a: LockEvent, b: LockEvent) => compareAsc(a.start, b.start))
}
export function getEventsTodayByLock(state: EventState, lockId: string) {
    return getFilteredEvents(state, (e) => {
        return isToday(e.start) && e.locks.includes(lockId)
    }).sort((a: LockEvent, b: LockEvent) => compareAsc(a.start, b.start))
}
export function getEventsInProgressTodayByLock(state: EventState, lockId: string) {
    return getFilteredEvents(state, (e) => {
        return (
            isToday(e.start) &&
            e.start.getTime() < new Date().getTime() &&
            e.end.getTime() > new Date().getTime() &&
            e.locks.includes(lockId)
        )
    }).sort((a: LockEvent, b: LockEvent) => compareAsc(a.start, b.start))
}
export function getFutureEvents(state: EventState) {
    return getFilteredEvents(state, (e) => {
        return !isToday(e.start) && isAfter(e.start, new Date())
    }).sort((a: LockEvent, b: LockEvent) => compareAsc(a.start, b.start))
}
export function getPastEvents(state: EventState) {
    return getFilteredEvents(state, (e) => {
        return !isToday(e.start) && isBefore(e.end, new Date())
    }).sort((a: LockEvent, b: LockEvent) => compareDesc(a.start, b.start))
}
