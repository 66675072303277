import { FunctionComponent } from 'react'
import styles from './Spinner.module.css'

export type SpinnerProps = {
    width: string
    height: string
    x?: number
    y?: number
    classes?: string
}

export const Spinner: FunctionComponent<SpinnerProps> = ({
    width,
    height,
    x,
    y,
    classes,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={classes}
            x={x}
            y={y}
            viewBox="0 0 20 20"
        >
            <circle
                cx="10"
                cy="10"
                r="8"
                className={styles['spinner-circle']}
            ></circle>
        </svg>
    )
}
