import { FunctionComponent, ReactNode } from 'react'
import close_icon from './../assets/svg/CloseMark.svg'

export type ToastDef = {
    type: ToastType,
    contents: ReactNode
}


export enum ToastType {
    Success,
    Error,
}

export type ToastProps = {
    def: ToastDef
    setToast: (toast: ToastDef | null) => void
}

export const Toast: FunctionComponent<ToastProps> = ({
    def,
    setToast,
}) => {
    let classes = 'border-[#FF8888] text-[#FF8888]'
    switch (def.type) {
        case ToastType.Error:
            classes = 'border-[#FF8888] text-[#FF8888]'
            break
        case ToastType.Success:
            classes = 'border-[#1BE1F2] text-[#1BE1F2]'
            break
    }

    
    return (
        <div
            className={
                'absolute bg-[#13132E] border-b z-[100] flex justify-between w-screen ' +
                classes
            }
        >
            <div>{/*Dummy for centering*/}</div>
            <div className="text-center flex flex-col justify-center">
                {def.contents}
            </div>
            <button
                onClick={() => {
                    setToast(null)
                }}
                className={
                    (document.body.scrollHeight > window.innerHeight)
                        ? ' pr-4 '
                        : ' pr-0 ' +
                        'transition-colors hover:bg-[#20203B] rounded-sm py-1'
                }
            >
                <>
                    <img src={close_icon} alt="Close"></img>
                </>
            </button>
        </div>
    )
}
