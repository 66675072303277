import { createTheme } from "@mui/material";


// To override default outline of the input fields or MUI

export const Theme = createTheme({
  
  typography: {
    fontFamily: 'Slalom Sans',
  },
  components: {
    MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover':{
                outline: '0.1rem solid #1BE1F2'
                
            },
          },
        },
      },
  
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Slalom Sans';
        src: url('../public/fonts/SlalomSans-Light.woff2') format('woff2'),
            url('../public/fonts/SlalomSans-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
      `,
    },
    MuiInputLabel:{
      styleOverrides:{
        root:{
          '&[data-shrink="true"]':{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingTop: ".06rem",
          paddingBottom: ".06rem",
          fontSize: "1.25rem",
          color: "#FFFFFF",
          backgroundColor: "#12122D",
          borderRadius: 4
        }
        }
      }
    }
    
    }
  },
);