import { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import Styles from './LockEvents.module.css'
import { Event } from './Event'
import { ModalView } from '../ModalView'
import { LockEvent } from '../../store/events'
import { ToastDef } from '../Toast'
import { fetchLocks, LockContext } from '../../store/locks'

interface IProps {
    events: LockEvent[]
    getToast: (toast: ToastDef | null) => void
    listTitle: string
    isDashboard:boolean
}

export const LockEvents: FunctionComponent<IProps> = ({ events, getToast,listTitle,isDashboard }) => {
    const [isModalShowing, setIsModalShowing] = useState(false)

    const [modalData, setModalData] = useState<{
        title: string
        content: ReactNode
    }>({
        title: '',
        content: <></>,
    })

    const getModalData = (newTitle: string, newContent: ReactNode) => {
        setModalData({ title: newTitle, content: newContent })
    }

    const [,lockDispatch] = useContext(LockContext)
    useEffect(() => { 
        fetchLocks(lockDispatch).catch((error) => {
            new Error(error);
        });
    }, [lockDispatch])

    return (
        <>
        <div className='px'>

        </div>
            <div className={Styles['event-list-title-container']}>
                <h3 className={Styles['event-list-title']}>
                    {listTitle}
                </h3>
            </div>
            {isModalShowing && (
                <ModalView title={modalData.title} setIsModalShowing={setIsModalShowing} isModalShowing={isModalShowing}>
                    <div className={Styles['modal-body']}>
                        {modalData.content}
                    </div>
                </ModalView>
            )}
            <div className={Styles['list-container']}>
                {events.map((event) => {
                    return (
                        <Event
                            key={event.id}
                            event={event}
                            getModalData={getModalData}
                            setIsModalShowing={setIsModalShowing}
                            getToast={getToast}
                            dashboard={isDashboard}
                        />
                    )
                })}
            </div>
        </>
    )
}
