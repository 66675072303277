import { API, PubSub } from 'aws-amplify'
import { createContext, Dispatch } from 'react'
import { APIName } from './auth'

export enum LockState {
    Locked,
    Unlocked,
    Offline,
}

async function getLocks(){
    const getLocksPath = '/locks'
    const response = await API.get(APIName, getLocksPath, {})
    return response.result
}

export enum LockDisplayState {
    Locked,
    Unlocked,
    Unlocking,
    Locking,
    Offline,
}

function toDisplayState(state: LockState) {
    switch (state) {
        case LockState.Locked:
            return LockDisplayState.Locked
        case LockState.Unlocked:
            return LockDisplayState.Unlocked
        case LockState.Offline:
            return LockDisplayState.Offline
    }
}

export class ILock {
    locationId: string
    deviceId: string
    name: string
    internalState: LockState
    displayState: LockDisplayState
    sub?: ZenObservable.Subscription

    constructor(
        id: string,
        name: string,
        state: LockState,
        locationId: string,
        displayState: LockDisplayState
    ) {
        this.deviceId = id
        this.name = name
        this.internalState = state
        this.locationId = locationId
        this.displayState = displayState
        this.sub = undefined
    }

    unlocked(this: ILock) {
        return (
            this.displayState === LockDisplayState.Unlocked ||
            this.displayState === LockDisplayState.Unlocking
        )
    }

    getTopic(this: ILock) {
        return `${this.locationId}/${this.deviceId}`
    }
}

type lockState = Map<string, ILock>

export enum lockActionType {
    LoadLocks,
    UserLockCommand,
    UserUnlockCommand,
    LockUpdate,
}

export type lockAction =
    | { type: lockActionType.LoadLocks; locks: ILock[] }
    | {
          type:
              | lockActionType.UserLockCommand
              | lockActionType.UserUnlockCommand
          lockId: string
      }
    | { type: lockActionType.LockUpdate; lockId: string; newState: LockState }

export const LockContext = createContext<
    [lockState, React.Dispatch<lockAction>]
>([new Map(), () => {}])

export function lockReducer(state: lockState, action: lockAction): lockState {
    switch (action.type) {
        case lockActionType.LoadLocks:
            action.locks.forEach((lock) => {
                state.set(lock.deviceId, lock)
            })
            break
        case lockActionType.UserUnlockCommand:
        case lockActionType.UserLockCommand:
            let to_change = state.get(action.lockId)
            if (!to_change) {
                throw new Error(
                    `Lock ${action.lockId} does not exist for lock command`
                )
            }
            PubSub.publish(
                to_change?.getTopic(),
                action.type === lockActionType.UserLockCommand
                    ? 'lock'
                    : 'unlock'
            )
            to_change.displayState =
                action.type === lockActionType.UserLockCommand
                    ? LockDisplayState.Locking
                    : LockDisplayState.Unlocking
            break
        case lockActionType.LockUpdate:
            let lock_change = state.get(action.lockId)
            if (!lock_change) {
                throw new Error(
                    `Lock ${action.lockId} does not exist for lock update`
                )
            }
            if (action.newState !== lock_change.internalState) {
                lock_change.displayState =
                    action.newState === LockState.Locked
                        ? LockDisplayState.Locked
                        : LockDisplayState.Unlocked
                lock_change.internalState = action.newState
            }
            break
    }

    return state
}

export const example_locks = new Map([
    [
        '0',
        new ILock(
            '0',
            'Fridge 1',
            LockState.Locked,
            '1',
            LockDisplayState.Locked
        ),
    ],
    [
        '1',
        new ILock(
            '1',
            'Fridge 2',
            LockState.Unlocked,
            '1',
            LockDisplayState.Unlocked
        ),
    ],
    [
        '2',
        new ILock(
            '2',
            'Bad Fridge',
            LockState.Offline,
            '1',
            LockDisplayState.Offline
        ),
    ],
])

export async function fetchLocks(dispatch: Dispatch<lockAction>) {
    const locks: ILock[] = []
    const result = await getLocks()
    let status: LockState

    result.forEach((element: {
        deviceId: string; 
        locationId: string; 
        name: string; 
        description: string; 
        status: string }) => {
            switch(element.status){
                case "LOCKED":
                    status = LockState.Locked
                    break
                case "UNLOCKED":
                    status = LockState.Unlocked
                    break
                case "OFFLINE":
                    status = LockState.Offline
            }
            locks.push(new ILock(element.deviceId, element.name, status,'1', toDisplayState(status)))         
    });
    dispatch({ type: lockActionType.LoadLocks, locks: locks})
}
