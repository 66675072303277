import { API } from 'aws-amplify'
import { createContext, Dispatch } from 'react'
import { APIName } from './auth'
import { LocationState } from './locations'

export type User = {
    name: string
    email: string
    title: string
    locationId: string
    isSponsor: boolean
    isLeader: boolean
}

export type UserState = Map<string, User>

export enum UserActionType {
    Load,
    Delete,
    Put,
}

export type UserAction =
    | { type: UserActionType.Load; People: User[] }
    | { type: UserActionType.Delete; UserEmail: string }
    | { type: UserActionType.Put; User: User }

export const UserContext = createContext<
    [UserState, React.Dispatch<UserAction>]
>([new Map(), () => {}])

export function UserReducer(state: UserState, action: UserAction): UserState {
    switch (action.type) {
        case UserActionType.Load:
            action.People.forEach((User) => {
                state.set(User.email, User)
            })
            break
        case UserActionType.Put:
            state.set(action.User.email, action.User)
            break
        case UserActionType.Delete:
            state.delete(action.UserEmail)
    }
    return state
}

export async function getUsers(
    users: UserState,
    dispatch: Dispatch<UserAction>,
    force?: boolean
) {
    if (users.size === 0 || force) {
        let ret = await API.get(APIName, '/users', {})
        dispatch({ type: UserActionType.Load, People: ret.result })
    }
}

export function FilterUsers(
    state: UserState,
    include: (User: User) => boolean
): User[] {
    let ret: User[] = []
    state.forEach((User) => {
        if (include(User)) {
            ret.push(User)
        }
    })
    return ret
}

export function GetUserEmailWithName(
    state:UserState,
    name:string
):string {
    let ret: string = ''
    state.forEach((User) => {
        if (name) {
            if (User.name === name) {
                ret = User.email
            }
        }
    })
    return ret

}

export function LeaderArray(state: UserState): User[] {
    return FilterUsers(state, (User) => User.isLeader)
}
export function SponsorArray(state: UserState): User[] {
    return FilterUsers(state, (User) => User.isSponsor)
}

export interface UserWithLocationName extends User {
    locationName: string
}

export function UserArray(
    state: UserState,
    locations: LocationState
): UserWithLocationName[] {
    let ret: UserWithLocationName[] = []
    state.forEach((user) => {
        const loc = locations.get(user.locationId)
        if (!loc)
            console.log(
                `Cannot find name for locationID ${user.locationId} on user ${user.email}. Silently discarding user. Please update the DB for all valid location IDs.`
            )
        else {
            ret.push({
                ...user,
                locationName: loc.name,
            })
        }
    })
    return ret
}
