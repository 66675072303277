import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Locklist } from './LockList'
import { Event } from '../../components/LockEventManagement/Event'
import { ModalView } from '../../components/ModalView'
import Styles from '../../components/LockEventManagement/Event.module.css'
import {
    EventContext,
    EventFetchState,
    fetchEvents,
    getEventsToday,
    getFutureEvents,
    LockEvent,
} from '../../store/events'
import { Toast, ToastDef } from '../../components/Toast'
import {
    AddEvent,
    LockEventFormType,
} from '../../components/AddOrModifyLockEvent/AddOrModifyLockEventModal'
import { Button } from '../../components/Button'

const Dashboard = () => {
    const [toast, setToast] = useState<null | ToastDef>(null)

    const [isModalShowing, setIsModalShowing] = useState(false)
    const [showAddEventModal, setShowAddEventModal] = useState(false)

    const [modalData, setModalData] = useState<{
        title: string
        content: ReactNode
    }>({
        title: '',
        content: <></>,
    })

    const getModalData = (newTitle: string, newContent: ReactNode) => {
        setModalData({ title: newTitle, content: newContent })
    }

    const [eventState, eventDispatch] = useContext(EventContext)
    const [todayEvents, setTodayEvents] = useState<null | LockEvent[]>(null)
    const [futureEvents, setFutureEvents] = useState<null | LockEvent[]>(null)
    useEffect(() => {
        if (eventState.state !== EventFetchState.Loaded) {
            // TODO: Handle errors here
            fetchEvents(eventDispatch).then()
        } else if (eventState.state === EventFetchState.Loaded) {
            setTodayEvents(getEventsToday(eventState))
            setFutureEvents(getFutureEvents(eventState))
        }
    }, [eventState, eventDispatch])
    const futureEventsToShow = useMemo(() => {
        const TotalEvents = 6
        if (todayEvents) {
            return Math.max(TotalEvents - todayEvents.length, 0)
        } else {
            return TotalEvents
        }
    }, [todayEvents])

    const [selectedLockId, setSelectedLockId] = useState<undefined | string>(
        undefined
    )

    return (
        <>
            {toast && <Toast def={toast} setToast={setToast} />}
            {isModalShowing && (
                <ModalView
                    title={modalData.title}
                    setIsModalShowing={setIsModalShowing}
                    isModalShowing={isModalShowing}
                >
                    <div className={Styles['modal-body']}>
                        {modalData.content}
                    </div>
                </ModalView>
            )}
            <div className=" flex lg:px-24 px-6 flex-col h-[calc(100vh-4rem)] lg:h-[calc(100vh-6rem)]">
                <div className="flex mt-4 lg:mt-12 flex-wrap justify-between">
                    <div>
                        <h1 className="lg:text-[44px] text-2xl font-bold">
                            Lock Control Home
                        </h1>
                    </div>
                    <Button
                        text="Add New Event"
                        plus_icon={true}
                        onClick={() => {
                            setShowAddEventModal(true)
                        }}
                    />
                </div>
                <h2 className="text-[22px] my-4">Available Locks</h2>
                <Locklist
                    selectedLockId={selectedLockId}
                    setSelectedLockId={setSelectedLockId}
                />
                <div className="grow overflow-y-scroll">
                    {todayEvents && todayEvents.length > 0 ? (
                        <div>
                            <h2 className="text-[22px] pb-2 sticky top-0 bg-black h-fit w-full z-40">
                                Today's Events
                            </h2>
                            {todayEvents &&
                                todayEvents.map((event) => {
                                    return (
                                        <Event
                                            key={event.id}
                                            event={event}
                                            getModalData={getModalData}
                                            setIsModalShowing={
                                                setIsModalShowing
                                            }
                                            dashboard={true}
                                            getToast={setToast}
                                            openLockId={(lockId: string) => {
                                                setSelectedLockId(lockId)
                                            }}
                                        />
                                    )
                                })}
                        </div>
                    ) : (
                        <></>
                    )}
                    <div>
                        <h2 className="text-[22px] pb-2 sticky top-0 bg-black h-fit w-full z-40">
                            {todayEvents ? 'Future' : 'Upcoming Lock'} Events
                        </h2>

                        {futureEvents &&
                            futureEvents
                                .slice(0, futureEventsToShow)
                                .map((event) => {
                                    return (
                                        <Event
                                            key={event.id}
                                            event={event}
                                            getModalData={getModalData}
                                            setIsModalShowing={
                                                setIsModalShowing
                                            }
                                            dashboard={true}
                                            getToast={setToast}
                                            openLockId={(lockId: string) => {
                                                setSelectedLockId(lockId)
                                            }}
                                        />
                                    )
                                })}
                    </div>
                </div>
            </div>
            {showAddEventModal && (
                <AddEvent
                    modalOpen={showAddEventModal}
                    setModalOpen={setShowAddEventModal}
                    modalType={LockEventFormType.ADD}
                    getToast={setToast}
                />
            )}
        </>
    )
}

export default Dashboard
