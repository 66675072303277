import { FunctionComponent, useReducer } from 'react'
import { useImmerReducer } from 'use-immer'
import { AuthContext, authReducer, AuthStatus } from './auth'
import { EventContext, EventFetchState, eventReducer } from './events'
import {
    default_locations,
    LocationContext,
    LocationReducer,
} from './locations'
import { LockContext, lockReducer } from './locks'
import { UserContext, UserReducer } from './users'

type Props = {
    children: JSX.Element
}

export const Contexts: FunctionComponent<Props> = ({ children }) => {
    
    const [authState, authDispatch] = useReducer(authReducer, {
        status: AuthStatus.Pending,
    })
    const [eventState, eventDispatch] = useReducer(eventReducer, {
        state: EventFetchState.Loading,
    })
    
    const [locks, lockDispatch] = useImmerReducer(lockReducer,new Map());
    
    const [users, userDispatch] = useImmerReducer(UserReducer, new Map())
    const [locations, locationDispatch] = useImmerReducer(
        LocationReducer,
        default_locations
    )

    return (
        <AuthContext.Provider value={[authState, authDispatch]}>
            <LocationContext.Provider value={[locations, locationDispatch]}>
                <LockContext.Provider value={[locks, lockDispatch]}>
                    <EventContext.Provider value={[eventState, eventDispatch]}>
                        <UserContext.Provider value={[users, userDispatch]}>
                            {children}
                        </UserContext.Provider>
                    </EventContext.Provider>
                </LockContext.Provider>
            </LocationContext.Provider>
        </AuthContext.Provider>
    )
}
