import { SvgIcon, SvgIconProps } from '@mui/material'

const iconSX = {
    '&:hover': {
        cursor: 'pointer',
        stroke: 'cyan',
    },
}

export function SearchIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={iconSX}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                
            >
                <path
                    d="M15.3667 15.7863L18.912 19.3277L19.9296 18.3092L16.3161 14.6957C16.0359 15.0893 15.7179 15.4545 15.3667 15.7863Z"
                    fill="#7A7A7A"
                />
                <path
                    d="M3.86326 10.2455C3.86555 11.8974 4.5228 13.4811 5.69091 14.6492C6.85902 15.8173 8.44267 16.4745 10.0946 16.4768C10.1589 16.4768 10.2223 16.4768 10.2866 16.4768C11.8062 16.4309 13.256 15.8286 14.3609 14.7843C14.7129 14.4533 15.0257 14.0829 15.293 13.6803C15.9315 12.7165 16.2873 11.5931 16.3202 10.4375C16.3202 10.3731 16.325 10.3098 16.325 10.2455C16.325 8.59281 15.6685 7.00784 14.4999 5.83923C13.3313 4.67062 11.7463 4.01411 10.0937 4.01411C8.44101 4.01411 6.85603 4.67062 5.68743 5.83923C4.51882 7.00784 3.8623 8.59281 3.8623 10.2455H3.86326ZM10.0946 5.45411C11.0423 5.45411 11.9686 5.73512 12.7566 6.2616C13.5445 6.78808 14.1586 7.53639 14.5213 8.41189C14.8839 9.2874 14.9788 10.2508 14.7939 11.1802C14.609 12.1096 14.1527 12.9634 13.4826 13.6335C12.8125 14.3036 11.9588 14.7599 11.0294 14.9448C10.0999 15.1296 9.13656 15.0348 8.26105 14.6721C7.38554 14.3095 6.63724 13.6953 6.11075 12.9074C5.58427 12.1195 5.30326 11.1931 5.30326 10.2455C5.30555 8.97542 5.81109 7.75805 6.70914 6.85999C7.6072 5.96193 8.82458 5.45639 10.0946 5.45411Z"
                    fill="#7A7A7A"
                />
            </svg>
        </SvgIcon>
    )
}

export function CalendarIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={iconSX}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99982 0.25C4.41403 0.25 4.74982 0.585786 4.74982 1V2.66763C7.57755 2.4158 10.4221 2.4158 13.2498 2.66763V1C13.2498 0.585786 13.5856 0.25 13.9998 0.25C14.414 0.25 14.7498 0.585786 14.7498 1V2.81644C16.2429 3.02754 17.4224 4.20847 17.6221 5.71484L17.7086 6.36779C18.0726 9.11412 18.0412 11.8984 17.6152 14.6359C17.4042 15.9919 16.3026 17.0335 14.9368 17.1681L13.7438 17.2858C10.5888 17.5969 7.41079 17.5969 4.25574 17.2858L3.06274 17.1681C1.697 17.0335 0.595338 15.9919 0.384328 14.6359C-0.0416297 11.8984 -0.0730704 9.11412 0.290965 6.36779L0.377517 5.71484C0.577193 4.20845 1.75674 3.02751 3.24982 2.81643V1C3.24982 0.585786 3.5856 0.25 3.99982 0.25ZM4.44498 4.2028C7.47417 3.90408 10.5254 3.90408 13.5546 4.2028L14.46 4.2921C15.3268 4.37757 16.0206 5.04854 16.1351 5.91194L16.2216 6.56489C16.2519 6.793 16.2792 7.02139 16.3037 7.25H1.69588C1.72036 7.02139 1.74772 6.79301 1.77796 6.5649L1.86451 5.91194C1.97896 5.04854 2.67278 4.37757 3.53953 4.2921L4.44498 4.2028ZM1.57665 8.75C1.47847 10.6381 1.5752 12.5332 1.86649 14.4052C1.97233 15.0854 2.52491 15.6078 3.20995 15.6754L4.40295 15.793C7.46009 16.0945 10.5395 16.0945 13.5966 15.793L14.7896 15.6754C15.4747 15.6078 16.0272 15.0854 16.1331 14.4052C16.4244 12.5332 16.5211 10.6381 16.4229 8.75H1.57665Z"
                    fill="#7A7A7A"
                />
            </svg>
        </SvgIcon>
    )
}

export function DropdownIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={iconSX}>
            <svg
                width="22"
                height="18"
                viewBox="0 0 15 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"
                    fill="#7A7A7A"
                />
            </svg>
        </SvgIcon>
    )
}
