import Styles from './Admin.module.css'
import SearchIcon from '../../assets/svg/search.svg'
import SortIcon from '../../assets/svg/sort.svg'
import EditIcon from '../../assets/svg/edit.svg'
import DeleteIcon from '../../assets/svg/trash.svg'
import ArrowIcon from '../../assets/svg/arrow.svg'
import SortArrowIcon from '../../assets/svg/sortarrow.svg'
import {
    User,
    UserContext,
    UserArray,
    UserWithLocationName,
    getUsers,
    UserActionType,
} from '../../store/users'

import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
} from '@tanstack/react-table'
import {
    Fragment,
    FunctionComponent,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Toast, ToastDef, ToastType } from '../../components/Toast'
import { ModalView } from '../../components/ModalView'
import { UserForm, UserFormType } from './UserForm'
import { LocationContext } from '../../store/locations'
import { API } from 'aws-amplify'
import { APIName } from '../../store/auth'
import { Button, ButtonType } from '../../components/Button'
import { ClickAwayListener } from '@mui/material'

export const Columns: ColumnDef<UserWithLocationName>[] = [
    {
        accessorKey: 'name',
        header: 'Name',
        enableSorting: true,
    },
    {
        accessorKey: 'title',
        header: 'Title',
        enableSorting: true,
    },
    {
        accessorKey: 'locationName',
        header: 'Location',
        enableSorting: true,
    },
    {
        header: 'Permissions',
        enableSorting: true,
        accessorFn: (person) => {
            let perms = ''
            if (person.isSponsor) {
                perms += 'Event Sponsor'
            }
            if (person.isLeader) {
                if (perms.length > 0) {
                    perms += ', '
                }
                perms += 'Designated Leader'
            }
            return perms
        },
    },
]

type AdminProps = {
    setLocationShowing: (state: boolean) => void
}

const Admin: FunctionComponent<AdminProps> = ({ setLocationShowing }) => {
    const [users, userDispatch] = useContext(UserContext)
    const [locations] = useContext(LocationContext)
    const [people_array, setPeopleArray] = useState<UserWithLocationName[]>([])
    useEffect(() => {
        getUsers(users, userDispatch).then(() => {
            setPeopleArray(UserArray(users, locations))
        })
    }, [users, locations, userDispatch])
    useEffect(() => {
        setLocationShowing(false)
        return () => {
            setLocationShowing(true)
        }
    })

    const [searchText, setSearchText] = useState('')
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'name',
            desc: false,
        },
    ])
    const columns = useMemo(() => {
        return Columns
    }, [])

    const table = useReactTable({
        data: people_array,
        columns: columns,
        state: {
            globalFilter: searchText,
            sorting,
        },
        initialState: {
            pagination: {
                pageSize: 10,
                pageIndex: 0,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        onGlobalFilterChange: setSearchText,
    })

    const [toast, setToast] = useState<null | ToastDef>(null)

    const [isModalShowing, setIsModalShowing] = useState(false)
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false)

    const [currentRowEmail, setCurrentRowEmail] = useState<null | string>(null)
    const currentRowSelected = useRef<null | HTMLDivElement>(null)

    const [modalData, setModalData] = useState<{
        title: string
        content: ReactNode
    }>({
        title: '',
        content: <></>,
    })

    const closeModal = () => {
        if (currentRowSelected.current) {
            currentRowSelected.current.scrollTo({
                left: 0,
                behavior: 'smooth',
            })
        }
        setIsModalShowing(false)
    }

    const configureDeleteModal = (user: User, submitted?: boolean) => {
        setModalData({
            title: `Delete User`,
            content: (
                <>
                    <h4 className="text-center my-16 mx-4">
                        Are you sure you want to delete this user?
                    </h4>
                    <div className="flex justify-center">
                        <Button
                            type={ButtonType.Secondary}
                            onClick={() => {
                                closeModal()
                            }}
                            text="Cancel"
                        />
                        <div className="m-2" />
                        <Button
                            type={ButtonType.Warning}
                            onClick={async () => {
                                configureDeleteModal(user, true)
                                API.del(APIName, '/user', {
                                    body: {
                                        email: user.email,
                                        locationId: user.locationId,
                                    },
                                })
                                    .then((e: { result: User }) => {
                                        setToast({
                                            type: ToastType.Success,
                                            contents: (
                                                <div>
                                                    User successfully deleted.
                                                </div>
                                            ),
                                        })
                                        userDispatch({
                                            type: UserActionType.Delete,
                                            UserEmail: e.result.email,
                                        })
                                    })
                                    .catch((e) => {
                                        setToast({
                                            type: ToastType.Error,
                                            contents: (
                                                <>
                                                    <div className="font-bold">
                                                        User delete Failed
                                                    </div>
                                                    <div>
                                                        Please see the console
                                                        for more details.
                                                    </div>
                                                </>
                                            ),
                                        })
                                    })
                                    .finally(() => {
                                        closeModal()
                                        getUsers(users, userDispatch, true)
                                    })
                            }}
                            submitting={submitted}
                            text="Delete User"
                        />
                    </div>
                </>
            ),
        })
        setIsModalShowing(true)
    }
    const configureEditModal = (user: User) => {
        setModalData({
            title: `Edit User`,
            content: (
                <UserForm
                    type={UserFormType.Edit}
                    setIsModalOpen={closeModal}
                    user={user}
                    setToast={setToast}
                />
            ),
        })
        setIsModalShowing(true)
    }

    return (
        <>
            {toast && <Toast def={toast} setToast={setToast} />}
            {isModalShowing && (
                <ModalView
                    title={modalData.title}
                    setIsModalShowing={closeModal}
                    isModalShowing={isModalShowing}
                >
                    <>{modalData.content}</>
                </ModalView>
            )}
            <div className="lg:px-24 px-6">
                <div className="flex mt-4 lg:mt-12 flex-wrap justify-between">
                    <div>
                        <h1 className="lg:text-[44px] text-xl font-bold">
                            Admin
                        </h1>
                        <h2 className="mt-4 lg:mt-6 text-xl mb-4">
                            Manage Users
                        </h2>
                    </div>
                    <Button
                        text="Add New User"
                        plus_icon={true}
                        onClick={() => {
                            setModalData({
                                title: `Add New User`,
                                content: (
                                    <UserForm
                                        type={UserFormType.Add}
                                        setIsModalOpen={setIsModalShowing}
                                        setToast={setToast}
                                    />
                                ),
                            })
                            setIsModalShowing(true)
                        }}
                    />
                </div>
                <div className="h-fit flex gap-2">
                    <div className="border border-[#2B2B53] rounded-sm shrink flex items-stretch min-w-0 focus:">
                        <input
                            type="text"
                            className="appearance-none bg-black/0 p-1 pl-2 shrink basis-64 min-w-0 w-64"
                            placeholder="Search"
                            value={searchText}
                            onChange={(event) =>
                                setSearchText(event.target.value)
                            }
                        />
                        <button className="bg-[#2B2B53] shrink-0 ">
                            <img
                                className="mx-1"
                                src={SearchIcon}
                                alt="Search"
                            />
                        </button>
                    </div>
                    <button
                        className={
                            'bg-[#2B2B53] shrink-0 rounded-sm lg:hidden relative ' +
                            (sortDropdownOpen && 'border border-cyan')
                        }
                        onClick={() => {
                            setSortDropdownOpen(!sortDropdownOpen)
                        }}
                    >
                        <img className="mx-3 my-2 " src={SortIcon} alt="Sort" />
                        {sortDropdownOpen && (
                            <ClickAwayListener
                                onClickAway={() => {
                                    setSortDropdownOpen(false)
                                }}
                            >
                                <div
                                    className="absolute mt-9 py-4 right-0 top-0 text-sm leading-3 w-48 bg-[#20203B] z-40"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <div className="text-left px-6 h-8 w-full flex items-center">
                                        <div className="pb-1 tracking-widest">
                                            SORT BY...
                                        </div>
                                    </div>
                                    <div className="mx-6 my-1 border-b border-[#C4C4C4]/25"></div>
                                    {table
                                        .getLeafHeaders()
                                        .map((header, idx) => (
                                            <>
                                                {idx !== 0 && (
                                                    <div className="mx-6 my-1 border-b border-[#C4C4C4]/25"></div>
                                                )}
                                                <button
                                                    className="text-left px-6 h-8 w-full flex items-center hover:bg-[#404065] transition-colors"
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <div className="mr-2">
                                                        {String(
                                                            header.column
                                                                .columnDef
                                                                .header
                                                        )}
                                                    </div>
                                                    {{
                                                        asc: (
                                                            <img
                                                                alt="Up Arrow"
                                                                src={
                                                                    SortArrowIcon
                                                                }
                                                            />
                                                        ),
                                                        desc: (
                                                            <img
                                                                alt="Down Arrow"
                                                                src={
                                                                    SortArrowIcon
                                                                }
                                                                style={{
                                                                    transform:
                                                                        'rotate(180deg)',
                                                                }}
                                                            />
                                                        ),
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                </button>
                                            </>
                                        ))}
                                </div>
                            </ClickAwayListener>
                        )}
                    </button>
                </div>
                <div className={Styles['grid-wrap']}>
                    {table.getLeafHeaders().map((header) => (
                        <div
                            key={header.id}
                            className={Styles['header']}
                            onClick={header.column.getToggleSortingHandler()}
                        >
                            {String(header.column.columnDef.header)}
                            {{
                                asc: <img alt="Up Arrow" src={SortArrowIcon} />,
                                desc: (
                                    <img
                                        alt="Down Arrow"
                                        src={SortArrowIcon}
                                        style={{ transform: 'rotate(180deg)' }}
                                    />
                                ),
                            }[header.column.getIsSorted() as string] ?? null}
                        </div>
                    ))}
                    <div className={Styles['header']}></div>
                    {table.getRowModel().rows.map((row) => (
                        <div className={Styles['item-row']} key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <div className={Styles['item']} key={cell.id}>
                                    {cell.getValue() as ReactNode}
                                </div>
                            ))}
                            <div className={Styles['actions']}>
                                <button
                                    onClick={() => {
                                        configureEditModal(row.original)
                                    }}
                                >
                                    <img src={EditIcon} alt="Edit" />
                                </button>
                                <button
                                    onClick={() => {
                                        configureDeleteModal(row.original)
                                    }}
                                >
                                    <img src={DeleteIcon} alt="Delete" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={Styles['mobile-list']}>
                    {table.getRowModel().rows.map((row) => (
                        <div className={Styles['hide-container']} key={row.id}>
                            <div
                                className={Styles['swipe-container']}
                                ref={
                                    currentRowEmail === row.original.email
                                        ? currentRowSelected
                                        : null
                                }
                            >
                                <div className={Styles['mobile-item']}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Fragment key={cell.id}>
                                            <div className={Styles['header']}>
                                                {String(
                                                    cell.column.columnDef.header
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    Styles[
                                                        'mobile-item-contents'
                                                    ]
                                                }
                                            >
                                                {cell.getValue() as ReactNode}
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                                <div className={Styles['swipe-dummy']} />
                            </div>
                            <div className={Styles['swipe-actions']}>
                                <button
                                    className={Styles['edit-action']}
                                    onClick={() => {
                                        setCurrentRowEmail(row.original.email)
                                        configureEditModal(row.original)
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    className={Styles['delete-action']}
                                    onClick={() => {
                                        setCurrentRowEmail(row.original.email)
                                        configureDeleteModal(row.original)
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={Styles['page-control']}>
                    {table.getCanPreviousPage() && (
                        <button
                            className={Styles['page-arrow']}
                            onClick={() => {
                                table.previousPage()
                            }}
                        >
                            <img
                                src={ArrowIcon}
                                alt="Left Arrow"
                                style={{ transform: 'rotate(180deg)' }}
                            />
                        </button>
                    )}
                    {Array.from({ length: table.getPageCount() }).map(
                        (_, idx) => {
                            var styles = Styles['page-selector']
                            if (idx === table.getState().pagination.pageIndex) {
                                styles += ` ${Styles['active']}`
                            }
                            return (
                                <button
                                    key={idx}
                                    className={styles}
                                    onClick={() => {
                                        table.setPageIndex(idx)
                                    }}
                                >
                                    {idx + 1}
                                </button>
                            )
                        }
                    )}
                    {table.getCanNextPage() && (
                        <button
                            className={Styles['page-arrow']}
                            onClick={() => {
                                table.nextPage()
                            }}
                        >
                            <img src={ArrowIcon} alt="Right Arrow" />
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export default Admin
